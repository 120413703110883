import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import backpage_45global from "/app/middleware/backpage.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  backpage_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.js"),
  "auth-admin": () => import("/app/middleware/authAdmin.js")
}