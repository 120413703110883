/* eslint-disable import/no-duplicates */
import { format, setDefaultOptions, add } from 'date-fns';
import { es } from 'date-fns/locale';

setDefaultOptions({ locale: es });

export default defineNuxtPlugin(() => {
  const formatDate = (date, formatValue, options) => format(date, formatValue || 'Pp', options);
  const addDate = (date, value) => add(date, value);

  return {
    provide: {
      formatDate,
      addDate,
    },
  };
});
