export default function () {
  const { backendUrl, checkoutBackendUrl, externalServiceToken, cmsAdminUrl, ultramsgUrl } = useRuntimeConfig().public;
  const BASE_PATH = `${backendUrl}/ims`;
  const BASE_PATH_V2 = `${BASE_PATH}/v2`;
  const BASE_INTERNAL = `${BASE_PATH}/api/v3/internal`;
  const BASE_EXTERNAL = `${BASE_PATH}/external_service/v1`;
  const BASE_PUBLIC = `${BASE_PATH}/api/v3/public`;
  const BASE_CMS = `${cmsAdminUrl}/api`;
  const BASE_NOTIFIER = `${BASE_PATH}/notifier/api/v1`;
  const BASE_CHECKOUT_V2 = `${checkoutBackendUrl}/v2`;
  const BASE_ULTRAMSG = `${ultramsgUrl}/ultramsg/v1`;

  return {
    ultramsg: {
      getGroups () {
        return `${BASE_ULTRAMSG}/groups/`;
      },
      showGroup (catalogId) {
        return `${BASE_ULTRAMSG}/groups/${catalogId}`;
      },
      updateGroup (groupId, catalogId) {
        return `${BASE_ULTRAMSG}/groups/${groupId}/project/${catalogId}`;
      },
      updateContact (contactId) {
        return `${BASE_ULTRAMSG}/contacts/${contactId}`;
      },
      getContacts (contactIds) {
        return `${BASE_ULTRAMSG}/contacts/${contactIds}`;
      },
      getMessages (catalogId) {
        return `${BASE_ULTRAMSG}/messages/${catalogId}`;
      },
    },
    checkout: {
      getReserve (separationId) {
        return `${BASE_CHECKOUT_V2}/separations/${separationId}`;
      },
      getSeparationValidations (separationId) {
        return `${BASE_CHECKOUT_V2}/separation-validations/${separationId}`;
      },
      getDocumentsHome () {
        return `${BASE_CHECKOUT_V2}/documents/request`;
      },
      getFuvLinks (separationId) {
        return `${BASE_CHECKOUT_V2}/separations/${separationId}/links?link_type=fuv`;
      },
      getLegalizationForm (separationId, customerId) {
        return `separations/${separationId}/customers/${customerId}/legalization-forms`;
      },
    },
    cms: {
      getEntries (collectionName) {
        return `${BASE_CMS}/${collectionName}`;
      },
      getEntriesByFilter (collectionName, filter) {
        return `${BASE_CMS}/${collectionName}?${filter}`;
      },
    },
    profile: {
      userInfo: `${BASE_PATH_V2}/profile/user_info`,
    },
    amenities: {
      getAllAmenities: `${BASE_INTERNAL}/amenities`,
    },
    projects: {
      createProject: `${BASE_INTERNAL}/projects`,
      getAmenities (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/relationship_amenities`;
      },
      getProject (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}`;
      },
      getDashboardUrl: (projectId) => {
        return `${BASE_INTERNAL}/projects/${projectId}/dashboard_url`;
      },
      projectById (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}`;
      },
      getBusiness (catalogId) {
        return `${BASE_INTERNAL}/projects/${catalogId}/opportunities?sort=updated_at+desc`;
      },
      getBusinessByFilter (catalogId, filter) {
        return `${BASE_INTERNAL}/projects/${catalogId}/opportunities?${filter}&sort=updated_at+desc`;
      },
      getPaymentPlans (catalogId) {
        return `${BASE_EXTERNAL}/projects/${catalogId}/payment_plans?token=${externalServiceToken}`;
      },
      getPaymentPlansByFilter (catalogId, filter) {
        return `${BASE_EXTERNAL}/projects/${catalogId}/payment_plans?token=${externalServiceToken}&${filter}`;
      },
      deletePaymentPlan (catalogId, paymentPlanId) {
        return `${BASE_INTERNAL}/projects/${catalogId}/quotations/${paymentPlanId}/delete`;
      },
      updatePlan (projectId, planId) {
        return `${BASE_EXTERNAL}/projects/${projectId}/payment_plans/${planId}?token=${externalServiceToken}`;
      },
      getSummaryInfo (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/summary_info`;
      },
      getCustomerFolder ({ projectId, cdpId, unitId, opportunityId }) {
        return `${BASE_INTERNAL}/projects/${projectId}/opportunities/1?cdp_id=${cdpId}&unit_catalog_id=${unitId}&opportunity_id=${opportunityId}`;
      },
      getPriceEscalation (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/price_rules`;
      },
      addPriceEscalationRule (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/price_rules/massive_create`;
      },
      deletePriceEscalationRule (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/price_rules/massive_destroy`;
      },
      getTypology (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/typology_info`;
      },
      getProjectParkings (projectId) {
        return `${BASE_PATH_V2}/projects/${projectId}/parking_list`;
      },
      getProjectStorageRooms (projectId) {
        return `${BASE_PATH_V2}/projects/${projectId}/storage_room_list`;
      },
      getSalesStats (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/sales_stats`;
      },
      getTypologySets (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/typology_sets?include_counters=1`;
      },
      getTypologySet (projectId, typologySetId) {
        return `${BASE_INTERNAL}/projects/${projectId}/typology_sets/${typologySetId}`;
      },
      getTypologySetDrafts (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/typology_set_drafts`;
      },
      getTypologySetDraft (projectId, typologySetDraftId) {
        return `${BASE_INTERNAL}/projects/${projectId}/typology_set_drafts/${typologySetDraftId}`;
      },
      addTypologySetDraft (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/typology_set_drafts`;
      },
      updateTypologySetDraft (projectId, id) {
        return `${BASE_INTERNAL}/projects/${projectId}/typology_set_drafts/${id}`;
      },
      publishTypologySetDraft (projectId, id) {
        return `${BASE_INTERNAL}/projects/${projectId}/typology_set_drafts/${id}/publish`;
      },
      getPropertiesNames (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/typology_info`;
      },
      priceList (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/price_lists`;
      },
      confirmPriceList (projectId, importId) {
        return `${BASE_INTERNAL}/projects/${projectId}/price_lists/${importId}/confirm_import`;
      },
      downloadInventory (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/price_lists/download_inventory`;
      },
      clearInventory (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/clean_inventory`;
      },
      uploadImages (projectId) {
        return `${BASE_PATH_V2}/projects/${projectId}}/project_images`;
      },
      deleteImages (projectId, imageId) {
        return `${BASE_PATH_V2}/projects/${projectId}}/project_images/${imageId}`;
      },
      property: {
        getPropertyInfo ({ projectId, propertyId }) {
          return `${BASE_INTERNAL}/projects/${projectId}/properties/${propertyId}`;
        },
        getPropertyStatus ({ projectId, propertyId }) {
          return `${BASE_PATH_V2}/projects/${projectId}/properties/${propertyId}/property_status`;
        },
        getPropertyAlert ({ projectId, propertyId }) {
          return `${BASE_INTERNAL}/projects/${projectId}/properties/${propertyId}/change_state_alerts`;
        },
        setPropertyAlert ({ projectId, propertyId, alertId }) {
          return `${BASE_INTERNAL}/projects/${projectId}/properties/${propertyId}/change_state_alerts/${alertId}`;
        },
        changeStates ({ projectId, propertyId }) {
          return `${BASE_INTERNAL}/projects/${projectId}/properties/${propertyId}/change_states`;
        },
        completeProperty (projectId, propertyId) {
          return `${BASE_INTERNAL}/projects/${projectId}/properties/${propertyId}/complete_property`;
        },
        getProperty (projectId, propertyId) {
          return `${BASE_INTERNAL}/projects/${projectId}/properties/${propertyId}`;
        },
      },
      commissions: {
        getCommissions (projectId) {
          return `${BASE_INTERNAL}/projects/${projectId}/take_rate_rules`;
        },
        deleteCommissions (projectId) {
          return `${BASE_INTERNAL}/projects/${projectId}/take_rate_rules/destroy`;
        },
        getCommission (projectId, commissionId) {
          return `${BASE_INTERNAL}/projects/${projectId}/take_rate_rules/${commissionId}`;
        },
        updateCommission (projectId, commissionId) {
          return `${BASE_INTERNAL}/projects/${projectId}/take_rate_rules/${commissionId}`;
        },
        getBusinessConditions (projectId) {
          return `${BASE_PATH_V2}/projects/${projectId}/business_conditions`;
        },
      },
      assets: {
        getAssets (projectId) {
          return `${BASE_INTERNAL}/projects/${projectId}/assets`;
        },
        updateAsset (projectId, assetId) {
          return `${BASE_INTERNAL}/projects/${projectId}/assets/${assetId}`;
        },
        updateOrder (projectId) {
          return `${BASE_INTERNAL}/projects/${projectId}/assets/update_order`;
        },
      },
    },
    financialPlans: {
      getFinancialPlans (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/payments_plans`;
      },
      addFinancialPlan (projectId) {
        return `${BASE_INTERNAL}/projects/${projectId}/payments_plans`;
      },
      getFinancialPlan (projectId, planId) {
        return `${BASE_INTERNAL}/projects/${projectId}/payments_plans/${planId}`;
      },
      deleteFinancialPlan (projectId, planId) {
        return `${BASE_INTERNAL}/projects/${projectId}/payments_plans/${planId}`;
      },
      updateFinancialPlan (projectId, planId) {
        return `${BASE_INTERNAL}/projects/${projectId}/payments_plans/${planId}`;
      },
    },
    paymentPlanCondition: {
      paymentPlanConditionById (projectId, planId, conditionId) {
        return `${BASE_INTERNAL}/projects/${projectId}/payments_plans/${planId}/payment_plan_restrictions/${conditionId}`;
      },
      paymentPlanConditions (projectId, planId) {
        return `${BASE_INTERNAL}/projects/${projectId}/payments_plans/${planId}/payment_plan_restrictions`;
      },
    },
    external: {
      enterprises: `${backendUrl}/ims/api/v3/external/enterprises`,
    },
    termsAndConditions: {
      termsAndConditionsPath () {
        return `${BASE_PUBLIC}/terms_conditions`;
      },
      set: `${BASE_PATH_V2}/accept_term`,
    },
    users: {
      getUsers: `${BASE_INTERNAL}/ims_users`,
      getUser: (id) => {
        return `${BASE_INTERNAL}/ims_users/${id}`;
      },
      getPermissions: `${BASE_INTERNAL}/ims_users/permissions_restricts`,
    },
    enterprises: {
      getEnterprises: `${BASE_INTERNAL}/enterprises`,
    },
    roles: {
      getRoles: (enterpriseId) => {
        return `${BASE_INTERNAL}/roles?enterprise_id=${enterpriseId}`;
      },
    },
    clients: {
      search: `${BASE_INTERNAL}/clients/search`,
      create: `${BASE_INTERNAL}/clients`,
    },
    shortenedUrls: `${BASE_PATH_V2}/shortened_urls`,
    notifier: {
      getSubscriptions (targetId, targetType) {
        return `${BASE_NOTIFIER}/subscriptions?target_id=${targetId}&target_type=${targetType}`;
      },
      addSubscription () {
        return `${BASE_NOTIFIER}/subscriptions`;
      },
      deleteSubscription (id) {
        return `${BASE_NOTIFIER}/subscriptions/${id}`;
      },
      updateSubscription (id) {
        return `${BASE_NOTIFIER}/subscriptions/${id}`;
      },
      getNotifications (targetId, targetType, projectId) {
        return `${BASE_NOTIFIER}/notifications?target_id=${targetId}&target_type=${targetType}&metadata[project_id]=${projectId}`;
      },
      markNotificationRead () {
        return `${BASE_NOTIFIER}/notification_reads`;
      },
    },
    backoffice: {
      rsyncOpportunities: `${BASE_INTERNAL}/admin/projects/update_opportunities`,
      getProject (id) {
        return `${BASE_INTERNAL}/admin/projects/${id}`;
      },
      getProperties (id) {
        return `${BASE_INTERNAL}/admin/properties/${id}`;
      },
      updatePriceOfProperty (id) {
        return `${BASE_INTERNAL}/admin/properties/${id}/update_manual_price_ims`;
      },
      syncWithCatalog (id) {
        return `${BASE_INTERNAL}/admin/projects/${id}/sync_properties_with_externals`;
      },
      syncPropertiesWithCatalog (id) {
        return `${BASE_INTERNAL}/admin/projects/${id}/sync_properties_with_catalog`;
      },
      syncPropertiesWithSobreplanos (id) {
        return `${BASE_INTERNAL}/admin/projects/${id}/sync_properties_with_sobreplanos`;
      },
      changePropertiesToUnavailable (id) {
        return `${BASE_INTERNAL}/admin/projects/${id}/change_available_properties`;
      },
      deleteInventory (id) {
        return `${BASE_INTERNAL}/admin/projects/${id}/clean_inventory`;
      },
      enablePaymentPlanFlow (id) {
        return `${BASE_INTERNAL}/admin/projects/${id}/enable_payment_plan_flow`;
      },
      removeProject (id) {
        return `${BASE_INTERNAL}/admin/projects/${id}/destroy_third_parties`;
      },
      deleteProjectImages (id) {
        return `${BASE_INTERNAL}/admin/projects/${id}/destroy_project_images`;
      },
    },
    escrowConfig: {
      findNationalities (method) {
        return `/methods/${method}/configs/nationalities`;
      },
      findCountries (method) {
        return `/methods/${method}/configs/countries`;
      },
      findStatesByCountry (method, countryId) {
        return `/methods/${method}/configs/states?country_id=${countryId}`;
      },
      findCitiesByState (method, countryId, stateId) {
        return `/methods/${method}/configs/cities?country_id=${countryId}&state_id=${stateId}`;
      },
    },
    simulator: {
      getClientSimulations ({ cdpId, filter }) {
        let queryParams = `token=${externalServiceToken}`;
        queryParams += filter ? `&${filter}` : '';
        return `${BASE_EXTERNAL}/payment_plans/${cdpId}?${queryParams}`;
      },
    },
  };
}
