import Rollbar from 'rollbar';

export default defineNuxtPlugin(() => {
  const { rollbarAccessToken, nodeEnv } = useRuntimeConfig().public;

  if (nodeEnv !== 'production') {
    return;
  }

  const rollbar = new Rollbar({
    accessToken: rollbarAccessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    hostSafeList: ['manager.lahaus.com'],
    payload: {
      environment: `lh-manager-${process.env.NODE_ENV}`,
      client: {
        javascript: {
          code_version: '1.0.0',
        },
      },
    },
    checkIgnore: (_isUncaught, _args, payload) => {
      let ignore = false;
      const ignoreCodes = [401, 422, 500, 502, 504, 520];
      payload.body.telemetry.forEach((e) => {
        if (ignoreCodes.includes(e.body.status_code)) {
          ignore = true;
        }
      });

      return ignore;
    },
  });

  return {
    provide: {
      rollbar,
    },
  };
});
