import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', () => {
  const userInfo = ref({
    id: null,
    enterpriseId: null,
    enterpriseName: null,
    enterpriseCode: null,
    enterpriseCountry: null,
    name: '',
    givenName: '',
    familyName: '',
    email: '',
    isLaHausUser: false,
    picture: '',
    restricts: [],
    metadata: {},
    termsAccepted: false,
    roleId: null,
    extra_info: {},
  });
  const isLaHausUser = computed(() => userInfo.value.isLaHausUser);
  const isAdmin = computed(() => userInfo.value.isAdmin);
  const userRestricts = computed(() => userInfo.value.restricts.map(({ name }) => name));
  const userFlowRestricts = computed(() => {
    return userInfo.value.restricts
      .filter(({ type_restrict: type }) => type === 'flow')
      .map(({ name }) => name);
  });
  const hasAcceptedTerms = computed(() => userInfo.value.termsAccepted);

  async function getUser () {
    const { $http, $auth } = useNuxtApp();
    const { profile, users } = useEndpoints();

    const response = await $http.get(profile.userInfo);
    if (response.ok === false) {
      $auth.logout();
      return;
    }

    const {
      id,
      email,
      auth0_metadata: auth0Metadata,
      enterprise_id: enterpriseId,
      enterprise_name: enterpriseName,
      enterprise_code: enterpriseCode,
      enterprise_country: enterpriseCountry,
      is_lahaus: isLaHausUser,
      is_admin: isAdmin,
      accept_terms: termsAccepted,
      role_id: roleId,
      extra_info: extraInfo,
    } = response;

    let restricts = await $http.get(users.getPermissions);
    let retries = 0;
    while (restricts.ok === false && retries < 3) {
      restricts = await $http.get(users.getPermissions);
      retries++;
    }

    if (retries === 3) {
      restricts = [];
    }

    userInfo.value = {
      id,
      email,
      enterpriseId,
      enterpriseName,
      enterpriseCode,
      enterpriseCountry,
      restricts,
      isLaHausUser,
      isAdmin,
      termsAccepted,
      name: auth0Metadata?.name,
      givenName: auth0Metadata?.given_name,
      familyName: auth0Metadata?.family_name,
      picture: auth0Metadata?.picture,
      metadata: auth0Metadata?.user_metadata,
      roleId,
      extraInfo,
    };
  }

  function changeTermsAndConditions () {
    userInfo.value.termsAccepted = true;
  }

  return {
    userInfo,
    isLaHausUser,
    isAdmin,
    getUser,
    userFlowRestricts,
    userRestricts,
    hasAcceptedTerms,
    changeTermsAndConditions,
  };
});
