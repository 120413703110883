import { datadogRum } from '@datadog/browser-rum';
import { version } from '../package.json';

const SESSION_SAMPLE_RATE = {
  development: 0,
  staging: 0,
  production: 20,
};

export default defineNuxtPlugin(() => {
  const { ddRumApplicationId, ddRumClientToken, nodeEnv } = useRuntimeConfig().public;

  if (nodeEnv !== 'production') {
    return;
  }

  const userStore = useUserStore();
  const unsubscribe = userStore.$subscribe((_mutation, { userInfo }) => {
    const {
      id,
      name,
      email,
    } = userInfo;

    datadogRum.setUser({
      id,
      email,
      name,
    });
    datadogRum.init({
      applicationId: ddRumApplicationId,
      clientToken: ddRumClientToken,
      site: 'datadoghq.com',
      service: 'lh-manager',
      allowedTracingUrls: [url => url.includes('lahaus.com')],
      env: nodeEnv,
      sessionSampleRate: 100,
      sessionReplaySampleRate: SESSION_SAMPLE_RATE[nodeEnv],
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      version,
    });
    datadogRum.startSessionReplayRecording();
    unsubscribe();
  });
});
