export default {
  index: {
    projects: 'Desarrollos',
    business: 'Negocios',
    noResults: 'No hay resultados para la búsqueda',
    showing: 'Mostrando {start} - {end} de {totalEntries}',
    documentation: 'Documentación',
    customerFolder: 'Expediente del cliente',
    paymentPlans: 'Planes de pagos',
    paymentPlanDetails: 'Detalle plan de pagos',
    monitor: 'Monitorear',
    setting: 'Configurar',
    inventory: 'Inventario',
    priceEscalation: 'Escalamiento de precios',
    takeRateEscalation: 'Escalamiento de comisiones',
    value: 'Valor',
    notApply: 'No aplica',
    projectsNotFound: 'No se encontraron desarrollos',
    acceptTermsAndConditions: 'Acepto los términos y condiciones',
    projectsHelper: 'Puedes buscar por nombre del desarrollo, ciudad, país o ID',
    client: 'Cliente',
    project: 'Desarrollo{plural}',
    separateUnit: 'Unidad apartada',
    unitPrice: 'Valor del negocio',
    rejectedReason: 'Motivos para no aprobar el plan',
    needHelp: '¿Necesitas ayuda?',
    chatWithCompanies: 'Chat con aliados',
    hideChatWithCompanies: 'Ocultar chat',
    companyOfProject: 'Aliado del proyecto',
    simulations: 'Simulaciones',
    toast: {
      inputFile: {
        invalidFormat: {
          title: 'Formato incorrecto',
          description: 'No se puede cargar el archivo. El formato no es compatible.',
        },
        singleFile: {
          title: 'Error al cargar',
          description: 'Solo se permite subir un archivo para este campo.',
        },
      },
      flowRestrict: {
        title: '¡Has abierto una puerta sin autorización!',
        description: 'Parece que no tienes permisos para acceder a esta página',
      },
    },
    companyName: 'Nombre de la empresa',
    company: 'Empresa',
    selectCompany: 'Selecciona o busca una empresa',
    projectName: 'Nombre del desarrollo',
    selectProject: 'Selecciona o busca un desarrollo',
    companyErrorMessage: {
      title: 'Empresa del desarrollo',
      description: 'Por favor indica la empresa a la que pertenece el desarrollo',
    },
  },
  base: {
    dropdownMultiple: {
      selectedOption: '{selectedOption} seleccionado',
      selectedOptions: '{selectedOptions} seleccionados',
      close: 'Cerrar',
    },
  },
  dashboards: {
    tabs: {
      summary: 'Ventas',
      early_access: 'Links de cupos',
      transactions: 'Transacciones',
    },
  },
  business: {
    index: {
      reports: 'Reportes completos',
      reportsDescription: 'Conoce las cifras del negocio',
      totalBusinessValue: 'Valor total de Negocios',
      totalSeparationsValue: 'Valor total de apartados',
      noBusinessToList: 'No hay negocios para listar',
      customerFolder: 'Expediente del cliente',
      banner: {
        title: 'Fecha de expiración de reserva: {time}',
        checkoutTitle: 'Checkout 1.0',
        checkoutDescription: 'Esta oportunidad fue creada por la versión anterior del Checkout. Si tienes algún requerimiento debes crear una PQR.',
        noInformationTitle: 'Información no disponible',
        noInformationDescription: 'No fue posible obtener la información solicitada. Si tienes algún requerimiento debes crear una PQR.',
      },
    },
    statusProperty: {
      available: 'Disponible',
      reserved: 'Reservado',
      separated: 'Apartado',
      sold: 'Vendido',
    },
    statusOpportunity: {
      separated: 'Apartado',
      documentation: 'Documentación',
      closed_won: 'Cerrada ganada',
      legalization_process: 'Proceso de firma',
      proposal: 'Cupo/Oferta',
      process: 'En proceso',
      closed_lost: 'Cerrada pérdida',
      reserved: 'Reservada/Acuerdo',
    },
    salesHeaders: {
      property: 'Propiedad',
      buyer: 'Comprador',
      buyerData: 'Datos del comprador',
      buyerDescription: 'Conoce los datos personales necesarios sobre el comprador',
      seller: 'Vendedor',
      business: 'Negocio',
      businessData: 'Datos del negocio',
      businessDescription: 'Conoce toda la información sobre esta transacción con respecto al contrato con La Haus.',
      statusOpportunity: 'Estado',
      statusProperty: 'Estado de propiedad',
      dateStatusProperty: 'Fecha estado de propiedad',
      priceBusiness: 'Precio del negocio',
      statusBusiness: 'Negocio',
      detail: 'Detalle',
      paymentUrl: 'Url de pagos',
      actions: 'Acciones',
      email: 'Correo',
      name: 'Nombre',
      viewSeparationManager: 'Ver datos de apartado',
    },
    actions: {
      paymentPlan: 'Plan de pagos',
      documents: 'Documentos',
    },
    customer: {
      opportunityId: 'ID oportunidad: {id}',
      separationId: 'Reservation: {id}',
      emailCopy: 'Mail: {email}',
      data: 'Ver datos del cliente',
      name: 'Nombre completo',
      document: 'Documento de identidad',
      email: 'Correo electrónico',
      phone: 'Número de contacto',
      address: 'Dirección',
      city: 'Ciudad',
      specialist: 'Especialista: {name}',
      createdAt: 'Fecha de creación',
    },
    process: {
      closedLostDescription: 'El comprador ha desistido. Ingresa para ver los documentos y generar la devolución',
      clientLink: 'Copiar enlace cliente',
      owner: 'Titular {name}',
      co_owner: 'Cotitular {idx}: {name}',
      title: {
        personalData: 'Datos personales',
        separationPayment: 'Apartado',
        legalization: 'Documentación',
        closedWon: 'Cerrada ganada',
        closedLost: 'Cerrada perdida',
      },
      step: 'Paso {start}/{end}',
      items: {
        completed: 'Completo',
        pending: 'Pendiente',
        pendingApproved: 'Pendiente de aprobación',
        personalData: 'Datos personales',
        contract: 'Contrato de mandato',
        separationPayment: 'Pago apartado',
        requiredDocuments: 'Documentos requeridos',
        fuv: 'FUV',
        hitchPayment: 'Pago enganche',
        paymentPlan: 'Plan de pagos',
        personalForm: 'Formulario de datos personales',
        finished: 'Requisitos terminados:',
        monthlyPayments: 'Mensualidades',
      },
      links: {
        createPaymentLink: 'Crear enlace de pago',
        manage: 'Gestionar',
        show: 'Ver',
        fuv: 'Enlace para titular',
        paymentPlan: 'Ver planes creados',
        voucher: 'Comprobante',
        download: 'Descargar',
      },
    },
    status: {
      title: 'Proceso de venta:',
      titles: {
        separated: 'Apartado',
        hitch: 'Enganche',
        documentation: 'Documentación',
        legalizationProcess: 'Firma de contrato',
        closedWon: 'Propiedad vendida',
        monthlyPayments: 'Mensualidades',
        personalData: 'Datos personales',
        paymentPlan: 'Plan de pagos',
        closedLost: 'Compra desistida',
      },
      description: {
        separated: {
          initial: 'El cliente ha reservado una unidad de forma exitosa y el pago está pendiente.',
          final: 'El pago fue aprobado. Ingresa para ver el comprobante.',
        },
        hitch: {
          initial: 'La compra se formalizará con este pago. El pago está pendiente',
          final: 'El pago fue aprobado. Ingresa para ver el comprobante.',
        },
        documentation: {
          initial: 'Se cargarán los documentos requeridos para la compra.',
          active: 'Ingresa para ver los documentos cargados y gestionar los pendientes.',
          activeAlly: 'Los documentos están completos y validados. Ingresa para verlos.',
          final: 'Todos los documentos requeridos fueron cargados exitosamente.',
        },
        legalizationProcess: {
          initial: 'El cliente revisará y firmará el contrato de compraventa.',
          final: 'El cliente ha firmado el contrato.',
        },
        closedWon: {
          initial: 'La transacción se ha completado.',
          final: 'La transacción se ha completado.',
        },
        monthlyPayments: {
          initial: 'Seguimiento de los pagos que realiza el cliente.',
          final: 'Seguimiento de los pagos que realiza el cliente.',
        },
        personalData: {
          initial: 'Seleccionar una propiedad y cargar los datos del cliente.',
          final: 'La información del cliente y la propiedad se ha guardado exitosamente.',
        },
        paymentPlan: {
          none: 'El cliente no ha seleccionado un plan de pagos.',
          warning: 'Plan de pago pendiente por aprobación.',
          success: 'El plan de pagos ha sido seleccionado.',
          disabled: 'No tenemos información sobre el plan de pagos.',
        },
        closedLost: 'El cliente ha desistido. Ingresa para ver los documentos y generar la devolución',
      },
      actions: {
        separated: {
          primary: 'Crear enlace de pago',
          contract: 'Enlace para firma de contrato',
          paymentLink: 'Enlace pago apartado',
        },
        hitch: {
          primary: 'Crear enlace de pago',
        },
        documentation: {
          primary: 'Enlace carga de documentos',
          secondary: 'Cargar documentos',
        },
        personalData: {
          primary: 'Enlace datos personales',
        },
        paymentPlan: {
          primary: 'Enlace selección plan de pago',
        },
      },
      pictogram: {
        separated: 'Pictograma para estado de apartado',
        hitch: 'Pictograma para estado de enganche',
        documentation: 'Pictograma para estado de documentación',
        legalizationProcess: 'Pictograma para estado de firma de contrato',
        closedWon: 'Pictograma para estado de venta reconocida',
        monthlyPayments: 'Pictograma para estado de mensualidades',
        paymentPlan: 'Pictograma para estado de plan de pagos',
      },
    },
    linkingData: 'Datos de vinculación',
    noLinkingData: 'No hay datos de vinculación',
    clipboard: {
      successCopyLink: {
        title: 'Copiado exitoso',
        description: 'Enlace copiado exitosamente',
      },
      errorCopyLink: {
        title: 'Error',
        description: 'No fue posible copiar el enlace',
      },
      successCopyEmail: {
        title: 'Copiado exitoso',
        description: 'El correo fue copiado exitosamente',
      },
    },
  },
  paymentPlan: {
    pendingApproved: {
      title: 'Por aprobar',
      description: 'Pagos que ingresaron y esperan por ser aprobados',
    },
    approved: {
      title: 'Aprobados',
      description: 'Pagos que fueron revisados y aprobados',
    },
    counterProposal: {
      title: 'Contrapropuestas',
      description: 'Propuestas de pago personalizadas de compradores',
    },
    rejected: {
      title: 'No aprobados',
      description: 'Pagos revisados y rechazados',
    },
    quotation: {
      title: 'Cotizaciones',
      description: 'Simulaciones de pago',
    },
    tableHeader: {
      property: 'Propiedad',
      client: 'Cliente',
      price: 'Precio',
      showPlan: 'Ver plan',
      customerSource: 'Origen plan de pagos',
      date: 'Fecha',
      state: 'Estado',
      createdBy: 'Creado por',
      project: 'Desarrollo',
    },
    noResults: {
      pending_approved: 'Aún no tienes planes de pagos por aprobar. Una vez que los clientes elijan una propiedad para comprar y generen un plan de pagos, lo verás en esta sección.',
      approved: 'Aún no tienes planes de pagos aprobados. Una vez que revises y apruebes los planes de pagos, los verás en esta sección.',
      counter_proposal: 'Aún no tienes contrapropuestas. Una vez que realices una contrapropuesta, las verás en esta sección.',
      rejected: 'Aún no tienes planes de pagos no aprobados. Una vez que revises y no apruebes algún plan de pagos, lo verás en esta sección.',
      default: 'Aún no hay cotizaciones. Una vez que realices una cotización, la verás en esta sección.',
    },
    delete: {
      title: 'Eliminar plan de pagos',
      description: '¿Estás seguro que deseas eliminar esta propuesta de plan de pagos?',
      input: {
        label: 'Motivo *',
        placeholder: 'Escribe el motivo por el cual se elimina el plan de pagos',
      },
    },
    toast: {
      successfulDelete: {
        title: 'Plan de pagos eliminado',
        description: 'El plan de pagos fue eliminado exitosamente',
      },
    },
    quantity: {
      title: '({quantity}) Planes de pago',
      description: 'Revisa las propuestas',
    },
  },
  company: {
    register: {
      title: 'Crea tu empresa',
      description: 'Bienvenido a La Haus, nos encanta tenerte aquí. Para comenzar crea tu empresa y empieza a usar todas nuestras herramientas.',
      accept: 'Acepto',
      terms: ' política de tratamiento de datos de La Haus.',
      country: 'Selecciona tu país',
      idRefLabel: 'Identificación de tu empresa',
      idRef: 'RFC',
      nameLabel: 'Nombre completo',
      namePlaceholder: 'Escribe el nombre de tu empresa',
      toast: {
        alreadyExists: 'El {idNumberPlaceholder} ya se encuentra registrado.',
        createdTitle: '¡La empresa se creó satisfactoriamente!',
        createdDescription: 'Te redirigiremos a la creación de usuario.',
      },
    },
    registerUser: {
      title: 'Crea tu usuario',
      description: 'Con éste podrás acceder a la información completa del desarrollo, ver los negocios y administrar tu perfil.',
      name: 'Nombres',
      lastName: 'Apellidos',
      email: 'Correo electrónico',
      phoneNumber: 'Número',
      phoneCountry: 'País',
      notification: 'Quiero que me notifiquen por WhatsApp.',
      createdUserTitle: '¡Usuario creado con éxito!',
      createdUserSubtitle: 'Comparte este link con las personas de tu equipo interesadas en monitorear o administrar el desarrollo.',
      copyLink: 'Copiar enlace de registro',
      goToProjects: 'Ver mis desarrollos',
      linkCopied: 'Enlace copiado',
    },
  },
  project: {
    applicationConditions: 'Condiciones de aplicación',
    activate: {
      title: 'Activar desarrollo',
      description: 'Al continuar, estarás publicando la información de este desarrollo en el sitio de La Haus y será visible para todos los visitantes.',
      button: 'Activar desarrollo',
    },
    inactivate: {
      title: 'Desactivar desarrollo',
      description: 'Al continuar, desactivarás la visualización de este desarrollo en el sitio de La Haus. Esto impedirá que los compradores puedan encontrarlo orgánicamente y desvinculará la pauta activa',
      button: 'Desactivar desarrollo',
    },
    toast: {
      success: {
        edit: {
          title: 'Información guardada',
          description: 'Tu información se guardo con éxito',
        },
      },
      error: {
        edit: {
          title: 'Error guardando información',
          description: 'Ocurrió un error al guardar tu información',
        },
        businessTags: {
          title: 'Tag del negocio incompleto',
          description: 'Debes seleccionar al menos un tag del negocio',
        },
      },
      updateProject: {
        title: 'Información guardada',
        description: 'Tu información se guardo con éxito',
      },
      invalidMail: {
        title: 'Correo no válido',
        description: 'El formato del correo no es válido',
      },
      invalidPhone: {
        title: 'Teléfono no válido',
        description: 'El número teléfonico no es válido',
      },
      invalidNumberField: {
        title: 'Campo numérico no válido',
        description: 'Por favor, ingrese valores numéricos en los campos correspondientes',
      },
      invalidMonth: {
        title: 'Campo de mes no válido',
        description: 'El valor numérico del mes no es válido',
      },
      invalidYear: {
        title: 'Campo de año no válido',
        description: 'El valor numérico del año no es válido',
      },
      previousDate: {
        title: 'Fecha no válida',
        description: 'La fecha ingresada debe ser posterior al presente mes',
      },
      rangeError: {
        title: 'Rango no permitido',
        description: 'Has ingresado un rango no permitido',
      },
      updateProperty: {
        title: 'Información guardada',
        description: 'La información de la propiedad se guardo con éxito',
      },
      duplicatedTypologyName: {
        title: 'Nombre de tipología no válido',
        description: 'El nombre de la tipología puede generar duplicidad con la tipología: "{name}"',
      },
    },
    settings: {
      transaction: {
        title: 'Transacción',
        subtitle: 'Configura el tipo de transacción para este desarrollo.',
      },
      payments: {
        title: 'Pagos',
        subtitle: 'Configura cómo recibir los pagos de los compradores.',
      },
      escalationsAndCommissions: {
        title: 'Escalamientos y comisiones',
        subtitle: 'Configura los valores que aplican en cada caso.',
      },
      botMenuItem: {
        title: 'Bot',
        subtitle: 'Actualiza o completa información de la capacitación.',
      },
      documents: {
        title: 'Documentación',
        subtitle: 'Selecciona los documentos que son requeridos para la compra.',
      },
      bot: {
        title: 'Configuración del bot',
        subtitle: 'Actualiza la base de conocimiento, enciende campañas y configura el servicio del bot.',
        knowledgeBaseItem: {
          title: 'Base de conocimiento',
          subtitle: 'Crea nuevas preguntas y respuestas, actualiza o elimina contenido existente sobre el desarrollo',
          questionLabel: 'Pregunta',
          searchLabel: 'Buscar',
          cancelLabel: 'Cancelar',
          questionPlaceholder: '¿Cuántos ascensores hay?',
          questionHelperText: 'Puedes buscar por tema o hacer una pregunta específica.',
          relatedAnswersTitle: 'Respuestas relacionadas:',
          addNewQuestionLink: 'Agregar nueva pregunta',
          saveLabel: 'Guardar',
          answerLabel: 'Respuesta:',
          answerPlaceholder: 'El desarrollo cuenta con 4 ascensores por torre.',
          confirmationModalTitle: '¿Estás seguro?',
          confirmationModalDescription: 'Acabas de modificar una respuesta, al contenido de un desarrollo. Esto modificará los puntos de contacto con el comprador. Si la información es correcta, continúa.',
          confirmationModalPrimaryActionName: 'Guardar',
          confirmationModalSecondaryActionName: 'Cancelar',
          noQuestionsTitle: 'No encontramos contenido relacionado.',
          changeQuestionDescription: 'Intenta con otra pregunta o crea una nueva sobre este tema.',
          createQuestion: 'Crear nueva pregunta',
          searchError: 'No fue posible obtener la información',
        },
      },
      paymentPlans: {
        title: 'Planes de pago',
        subtitle: 'Crea o edita planes de pago estándar permitidos.',
      },
      commissions: {
        title: 'Comisiones',
        subtitle: 'Crea reglas',
      },
      inventory: {
        title: 'Inventario',
        subtitle: 'Carga, modifica o actualiza tu inventario.',
      },
      basicInfo: {
        title: 'Información básica',
        subtitle: 'Configura ubicación, nombre e información básica general.',
      },
      amenities: {
        title: 'Amenidades',
        subtitle: 'Crea o actualiza las amenidades.',
        selectFromList: 'Selecciona de la lista',
        disclaimerNote: 'Si alguna de tus amenidades no aparece en esta lista puedes comunicarlo a tu KAM para su creación.',
        toast: {
          title: 'Se ha actualizado la información',
          description: 'Las amenidades fueron almacenadas con éxito',
        },
      },
      multimediaContent: {
        title: 'Contenido multimedia',
        subtitle: 'Gestiona el contenido multimedia que verán los compradores.',
        tabs: {
          galleries: 'Galerías',
          links: 'Enlaces',
          brochure: 'Brochure',
        },
        galleries: {
          associate: 'Asociar',
          options: 'Opciones',
          maximumQuantity: 'Cantidad máxima: {quantity}',
          image: 'imagen',
          images: 'imágenes',
          imageAlert: 'Para subir más imágenes debes asociar primero las cargadas.',
          reminder: 'Recuerda:',
          reminderList: {
            1: 'Evitar imágenes repetidas por tipología, esto confunde la selección del usuario.',
            2: 'Puedes ordenar las imágenes arrastrándolas dentro de la categoría asociada.',
          },
        },
      },
      cms: {
        title: 'CMS',
        subtitle: 'Crea y edita contenido para tu desarrollo.',
      },
      whatsapp_group: {
        title: 'Grupo de whatsapp',
        subtitle: 'Gestiona los grupos de whatsapp con el aliado.',
        phone: 'Teléfono',
        message: 'Mensaje',
        date: 'Fecha',
        group_id: 'Grupo de whatsapp',
        successUpdate: 'Se ha actualizado correctamente',
        msgTitle: 'Historico de mensajes',
      },
    },
    configDocuments: {
      toast: {
        success: {
          create: {
            title: 'Información guardada',
            description: 'Tu configuración se guardó con éxito',
          },
          update: {
            title: 'Información guardada',
            description: 'Tu configuración se editó con éxito',
          },
          delete: {
            title: 'Información eliminada',
            description: 'Tu configuración se eliminó con éxito',
          },
        },
      },
      deleteDocument: {
        title: 'Eliminar configuración de documento',
        description: '¿Estás seguro/a que quieres eliminar esta configuración de documento?',
      },
      document_type: 'Tipo de documento',
      addConfig: 'Agregar nuevo documento',
      required: 'Obligatorio',
      person_type: 'Tipo de persona',
      person_subtype: 'Ocupación',
      number_of_files: 'Número de archivos',
      document: 'Nombre del documento',
      user_description: 'Descripción',
      user_description_upsert: 'Descripción (puedes personalizar este valor)',
      phase: 'Etapa',
      actions: 'Acciones',
      country: 'Pais',
      customer: 'Documento del cliente',
      purchase: 'Documento de la compra',
      domain: 'Dominio',
      isRequired: 'Indique si es obligatorio',
      requiredCard: 'Es obligatorio',
      notRequired: 'Es opcional',
      labels: {
        natural: 'Física',
        legal: 'Moral',
      },
      personSubtype: {
        EMPLOYEE: 'Empleado',
        FREELANCER: 'Independiente',
        RETIRED: 'Pensionado',
        NO_APPLY: 'No aplica',
      },
      personType: {
        LEGAL_ENTITY: 'Persona jurídica',
        NATURAL: 'Natural',
        PHYSICAL: 'Persona física',
        MORALE: 'Persona moral',
      },
      numberOfFiles: '{num} archivos',
      title: 'Documentación',
      description: 'Configura los documentos requeridos según el tipo de comprador.',
    },
    cms: {
      title: 'CMS',
      description: '',
    },
    escalations: {
      title: 'Escalamientos y comisiones',
      description: '',
    },
    financialPlan: {
      deletePlan: {
        title: 'Eliminar plan',
        description: '¿Estás seguro/a que quieres eliminar el plan "{planName}"?',
      },
      applyTo: {
        label: 'Aplica a',
        all: {
          label: 'Todos',
        },
        typology: {
          name: 'Tipologías',
          label: 'Aplica a tipologías',
          placeholder: 'Selecciona tipologías',
          one: 'Aplica a tipología {name}',
          two: 'Aplica a tipologías {one} y {two}',
          more: 'Aplica a tipologías {names} y {finalName}',
        },
        name: {
          name: 'Propiedades',
          label: 'Aplica a propiedades',
          placeholder: 'Selecciona propiedades',
          one: 'Aplica a unidad {name}',
          two: 'Aplica a propiedades {one} y {two}',
          more: 'Aplica a propiedades{names} y {finalName}',
        },
      },
      toast: {
        success: {
          create: {
            title: 'Plan creado',
            description: 'El plan de pagos fue creado exitosamente',
          },
          update: {
            title: 'Plan enditado',
            description: 'El plan de pagos fue editado exitosamente',
          },
          delete: {
            title: 'Plan eliminado',
            description: 'El plan de pagos fue eliminado exitosamente',
          },
        },
        error: {
          create: {
            title: 'Error guardando información',
            description: 'Ocurrió un error al guardar tu plan',
          },
        },
      },
      typeQuotes: 'Tipo de cuotas',
      monthlyDateRange: 'Fecha fin de pagos de mensualidades',
      monthlyFixedRange: 'Fecha fin de pagos por número de cuotas fijas',
      monthlyPaymentStartDate: 'Fecha inicio de pagos mensualidades',
      monthlyPaymentEndDate: 'Fecha fin de pagos mensualidades',
      paymentsNumber: 'Número de cuotas fijas',
      reservation_fee: 'Apartado',
      hitch: 'Enganche',
      monthlyPayments: 'Mensualidades',
      credit: 'Pago final',
      additionFee: 'Adición a cuota',
      description: 'Crea planes de pago a la medida para tu desarrollo, si tienes algún plan especial, puedes solicitar la creación con tu KAM.',
      descriptionCreatePlan: 'Crea plan de pago estándar para la comercialización del desarrollo.',
      personalizedPlanCheck: '¿Acepta planes de pago personalizados?',
      addPlan: 'Agregar plan de pago',
      textCredit: 'El pago final se calculará según los porcentajes que ingresaste previamente.',
      name: {
        label: 'Nombre del plan',
        placeholder: 'Escríbelo como lo verán los compradores',
      },
      hitchInput: {
        label: 'Porcentaje del enganche',
        placeholder: '5',
      },
      monthlyPaymentsInput: {
        label: 'Porcentaje de las mensualidades',
        placeholder: '40',
      },
      additional: 'Adicionales',
      discount: 'Descuento',
      additionalPlaceholder: '% Ingresa el porcentaje',
      surcharge: 'Recargo',
      paymentConditions: 'Condiciones de pago',
      addPaymentConditions: 'Crear condiciones de pago',
      paymentsConditions: {
        date: 'Fecha',
        quotaNumber: 'Cuota #',
        finalDateLabel: 'Cuándo finaliza la condición: ',
        conditionValueLabel: 'Valor a pagar durante la condición ',
        totalPay: 'Valor total a pagar',
        minPay: 'Valor mínimo a pagar total',
      },
      flexiblePlan: {
        name: {
          label: 'Nombre del plan',
          placeholder: 'Escríbelo como lo verán los compradores',
        },
        conceptName: {
          label: 'Nombre del concepto',
          placeholder: 'Ej: Pago inicial',
        },
        installments: {
          label: 'Cuotas en las cuales se pagará',
          placeholder: 'Selecciona las cuotas del pago',
          helperText: 'Ingresa una fecha de fin de pagos válida para activar este campo | Igresa el número de la cuota final de pagos para activar este campo',
        },
        installmentNumber: 'Cuota {number} ({date})',
        dateInitialPayment: 'Inicio de plan de pagos',
        dateEndPayment: 'Fecha de fin de plan de pagos',
        whenPaymentEnds: 'Fin de plan de pagos',
        numberFinalQuote: '# cuota final',
        conceptType: 'Tipo de concepto',
        valueType: 'Tipo de valor',
        valueToPay: 'Valor a cubrir',
        tableHeaders: {
          concept: 'Concepto',
          quote: 'Cuota',
          value: 'Valor',
        },
        hitch: 'Enganche',
        monthlyPayment: 'Mensualidades',
        confirmationDeleteConcept: {
          title: 'Eliminar concepto del plan de pagos',
          description: '¿Estás seguro que deseas eliminar el concepto "{concept} cuota {installments}" del plan de pagos?',
        },
        amountsToPay: {
          title: 'Valores a pagar',
          description: 'Agrega los valores que componen el plan de pagos, especificando su tipo y el momento en qué debe pagarse.',
        },
        detailPlan: 'Detalle del plan',
      },
      typePlan: {
        default: 'Plan estándar tradicional',
        flexible: 'Plan estándar flexible',
      },
    },
    financialPlans: 'Planes de pago',
    paymentConditions: {
      deleteCondition: {
        title: 'Eliminar condición',
        description: '¿Estás seguro/a que quieres eliminar esta condición?',
      },
      modalTitle: 'Crear condición del plan financiero',
      applyUntilPlaceholder: 'Ingresa',
      totalPaymentPlaceholder: 'Total',
      minPaymentPlaceholder: 'Valor mínimo mensual',
      quotaTitle: 'Cuota {quota}',
      dateTitle: 'Fecha: {date}',
      oneQuotaTitle: 'En cuota {quota}',
      oneDateTitle: 'En {date}',
      quotaRangeTitle: 'Desde cuotas {init} hasta {final}',
      dateRangeTitle: 'Desde {init} hasta {final}',
      totalPaymentValue: 'Valor total a pagar: {totalValue}',
      minPaymentValue: 'Valor mínimo {minValue}',
      numberCondition: 'Condición {index}',
    },
    property: {
      form: {
        price: {
          label: 'Precio total',
          placeholder: 'Valor del cierre',
          errors: {
            priceLessThanInitial: 'El precio total no debería ser menor al precio inicial',
          },
        },
        level: {
          label: 'Piso/Sección',
          placeholder: 'Ingresa el piso o sección',
        },
        nomenclature: {
          label: 'Nomenclatura',
          placeholder: 'Ingresa la nomenclatura',
        },
      },
      completePropertyBanner: {
        title: 'Detalle de propiedad pendiente',
        description: 'Ponte en contacto con el desarrollador y el comprador para concretar la propiedad específica a comprar',
        cta: 'Completar propiedad',
      },
    },
    bannerCreateProject: {
      title: 'No tienes desarrollos',
      description: 'Aún no has creado ningún desarrollo. Para continuar completa la información básica',
      cta: 'Crear desarrollo',
    },
    form: {
      basicInformation: {
        name: {
          label: 'Nombre del desarrollo',
          placeholder: 'Ingresa el nombre del desarrollo',
        },
        propertyType: {
          label: 'Tipo de propiedad',
          placeholder: 'Selecciona el tipo de propiedad',
        },
        deliveryDate: {
          label: 'Fecha de entrega',
          helperText: 'Esta es una fecha aproximada para el cálculo de mensualidades',
        },
        location: {
          label: 'Ubicación',
          placeholder: 'Ingresa la dirección del desarrollo aquí',
          errorMessage: {
            title: 'Ubicación invalida',
            description: 'La ubicación del desarrollo no es válida o está fuera del areá de cobertura de La Haus',
          },
          successMessage: {
            title: 'Ubicación valida',
            description: 'La ubicación del desarrollo está en el areá de cobertura de La Haus',
          },
        },
        description: {
          label: 'Descripción',
          placeholder: 'Ingresa la descripción del desarrollo',
        },
        businessTags: {
          label: 'Tags del negocio',
          placeholder: 'Selecciona los tags del negocio',
        },
      },
      contact: {
        name: {
          label: 'Nombre',
          placeholder: 'Ingresa el nombre del contacto',
        },
        phone: {
          label: 'Teléfono',
          placeholder: 'Ingresa el teléfono del contacto',
        },
        email: {
          label: 'Correo',
          placeholder: 'Ingresa el correo electrónico del contacto',
        },
      },
      propertyType: {
        houses: 'Casa',
        apartments: 'Apartamento',
        lots: 'Lote',
        houseAndApartment: 'Casas y apartamentos',
      },
      paymentPlan: {
        hitchInput: {
          label: 'Valor del enganche',
          placeholder: '5',
        },
        monthlyPaymentsInput: {
          label: 'Valor de las mensualidades',
          placeholder: '40',
        },
        button: {
          save: 'Guardar plan de pagos',
        },
        requiredPlanMessage: {
          title: 'Plan de pagos',
          description: 'Por favor cree un plan de pagos',
        },
        multimediaContent: {
          title: 'Contenido multimedia desarrollo',
          description: 'Agrega todo el contenido que quieres que vean los compradores para conocer mejor tu desarrollo. Puedes cargar archivos o ingresar links.',
        },
        finishProcess: {
          title: '¡Hemos creado tu desarrollo exitosamente!',
          description: 'Los datos que enviaste serán revisados por nuestro equipo de calidad y tu desarrollo será visible en nuestro sitio web en las próximas 24 horas.',
          bannerInformation: 'Puedes continuar con la información faltante en el administrador de tu desarrollo.',
        },
      },
      section: {
        section: {
          contactInfo: 'Datos de contacto',
          basicInfo: 'Información básica',
          paymentPlan: 'Planes de pago',
          multimedia: 'Múltimedia',
          step: 'Paso {currentStep}/{totalStep}',
        },
      },
      successProjectCreation: {
        title: 'Información guardada',
        description: 'La información del desarrollo se guardó con éxito',
      },
      createProject: 'Crear desarrollo',
    },
    bot: {
      knowledgeBase: {
        facts: {
          source: 'Fuente',
          fact: 'Hecho',
          status: 'Estado',
          createdAt: 'Creación',
          noFacts: 'No se encontraron hechos',
          createFact: 'Agregar hecho',
          actions: 'Acciones',
          deleteTitle: 'Eliminar hecho',
          deleteDescription: '¿Estas seguro de eliminar este hecho?',
          delete: 'Eliminar',
          deleteConfirmationTitle: 'Hecho eliminado',
          deleteConfirmation: 'El hecho fue eliminado correctamente',
          factDeleted: 'Este hecho se encuentra eliminado y no puedes modificarlo',
          factsNumber: 'Número total de hechos: #counter',
          factsFilterNumber: 'Mostrando #counter de #total hechos',
          showDeletedFacts: 'Ver hechos eliminados',
          uploadTranscript: 'Procesar Transcripción',
          uploadTranscriptTitle: 'Carga de transcripcion en proceso',
          uploadTranscriptDescription: 'Extracción de hechos en proceso, por favor espere unos minutos',
          question: 'Preguntar...',
          search: 'Buscar',
          questionLabel: 'Busqueda abierta',
        },
        errors: {
          missingParams: {
            title: 'Faltan Parámetros',
            all: 'Los parámetros groupKey y type son obligatorios. Por favor, verifica la URL e intenta nuevamente',
            groupKey: 'El parámetro groupKey es requerido para continuar. Por favor, verifica la URL.',
            type: 'El parámetro type es obligatorio para acceder a esta página. Por favor, verifica la URL.',
            id: 'El parámetro id es obligatorio para acceder a esta página. Por favor, verifica la URL.',
          },
          facts: {
            title: 'Ha ocurrido un error',
            notFound: 'Este hecho no existe',
            integrityError: 'Ya existe un hecho con la misma información',
            factPending: 'El hecho esta pendiente de ser verificado para poder ser publicado',
            factDeleted: 'El hecho se encuentra eliminado',
            factDifferent: 'Ya existe un hecho con la misma información',
            evaluateFact: 'El hecho debe ser evaluado antes de ser publicado',
            techinalError: 'Ha ocurrido un error técnico, notifique al equipo de Plataforma',
            invalidTranscript: 'La transcripcion no es valida, por favor verifique se encuentre cargada correctamente',
          },
        },
        form: {
          fields: {
            fact: {
              label: 'Hecho',
              placeholder: 'Hecho',
              required: 'Este campo es obligatorio',
              max: 'Este campo no puede superar los 500 caracteres',
            },
            isPending: {
              label: 'Pendiente de revisión',
              required: 'Este campo es obligatorio',
            },
            isPublished: {
              label: 'Publicado y activo en Sam',
              required: 'Este campo es obligatorio',
            },
            isPublic: {
              label: 'Visible para clientes y usuarios externos de La Haus',
              required: 'Este campo es obligatorio',
            },
          },
          actions: {
            submit: {
              title: 'Guardar',
            },
            delete: {
              title: 'Eliminar',
            },
            evaluate: {
              title: 'Probar con Sam',
            },
          },
        },
        finishProcess: {
          createFact: {
            title: 'Hecho creado',
            description: 'El hecho fue creado exitosamente',
          },
          updateFact: {
            title: 'Hecho actualizado',
            description: 'El hecho fue actualizado exitosamente',
          },
          deleteFact: {
            title: 'Hecho eliminado',
            description: 'El hecho fue eliminado correctamente',
          },
        },
        factDetail: {
          title: 'Más información',
          metadata: {
            title: 'Metadata',
            city: 'Ciudad',
            businessHub: 'HUB',
            countryCode: 'Código de país',
            type: 'Base de conocimiento',
          },
          audit: {
            title: 'Auditoria',
            createdBy: 'Creado por',
            createdAt: 'Creado en',
            updatedBy: 'Actualizado por',
            updatedAt: 'Actualizado en',
            deletedBy: 'Eliminado por',
            deletedAt: 'Eliminado en',
            filled: 'Revisado',
            filledBy: 'Revisado por',
            filledAt: 'Revisado en',
            published: 'Publicado',
            publishedBy: 'Publicado por',
            publishedAt: 'Publicado en',
          },
        },
        evaluationResult: {
          title: 'Resultados de Evaluaciones',
          listing: 'Contradicciones con el IMS',
          kb: 'Contradicciones con otros Hechos',
          interaction: 'Contradicciones con video/llamadas de Guías de La Haus',
          redundancy: 'Redundancia',
          irrelevant: 'Irrelevante',
          testing: 'No Relacionado',
          contradiction: 'Contradicción',
          syntheticEvaluation: {
            title: 'Prueba con Sam',
            answer: 'Respuesta',
            question: 'Pregunta de prueba',
            factused: '¿Se uso el hecho?',
            precision: 'Precisión',
            isanswered: 'Respuesta Correcta',
            reason: 'Explicación',
            evaluatedBy: 'Evaluado por',
            evaluatedAt: 'Evaluado el',
            toast: {
              title: 'Prueba con Sam exitosa',
              description: 'La prueba se realizó con exito',
            },
          },
        },
      },
    },
  },
  projectCard: {
    sold: 'Vendido',
    of: 'de',
    soldUnits: 'unidades vendidas',
    labelTag: {
      published: 'Publicado',
      notPublished: 'No publicado',
    },
    unitsAvailable: '{quantity} disponibles',
    availableUnit: '{quantity} disponible',
    partialInventory: 'Inventario parcial',
    completeInventory: 'Inventario completo',
  },
  button: {
    selectAll: 'Seleccionar todo',
    selectAllItems: 'Seleccionar todos',
    seeMore: 'Ver más',
    search: 'Buscar',
    continue: 'Continuar',
    save: 'Guardar',
    dataSheet: 'Ficha técnica',
    edit: 'Editar',
    add: 'Agregar',
    areYouSure: '¿Estás seguro de realizar esta acción?',
    goToPdp: 'Ir a la PDP',
    downloadBrochure: 'Ver brochure',
    cancel: 'Cancelar',
    createCondition: 'Crear condición',
    delete: 'Eliminar',
    goToQuotator: 'Cotizar',
    downloadInventory: 'Descargar inventario',
    clearInventory: 'Limpiar inventario',
    activate: 'Activar',
    inactivate: 'Desactivar',
    accept: 'Aceptar',
    downloadPdf: 'Descargar PDF',
    rejectPlan: 'No aprobar plan',
    approvePlan: 'Aprobar plan',
    editPlan: 'Enviar propuesta',
    send: 'Enviar',
    post: 'Publicar',
    unpublish: 'Despublicar',
    clear: 'Limpiar',
    finish: 'Finalizar',
    understood: 'Entendido',
    newSimulation: 'Nueva simulación',
  },
  acronym: {
    undefined: '-',
    annual_effective: 'E.A',
    monthly_effective: 'E.M',
  },
  ariaLabel: {
    imageCover: 'imagen de portada de ',
  },
  footer: {
    termsAndConditions: 'Términos y condiciones',
    cookies: 'Cookies',
    data: 'Tratamiento de datos',
    systemStatus: 'Estado del sistema',
  },
  actionMenu: {
    notification: 'Notificaciones',
    users: 'Usuarios',
    logout: 'Cerrar sesión',
  },
  basicInformation: {
    description: 'Completa o edita la información general sobre el desarrollo.',
    generalInformation: 'Información general',
    location: 'Ubicación',
    financialInformation: 'Información financiera',
    contactInformation: 'Datos de contacto',
    title: 'Información básica',
    addContact: 'Agregar contacto',
    contactAction: '{action} contacto',
    placeholderBusinessTags: 'Selecciona los tags del negocio',
    locationInfo: {
      description: 'Para crear la ubicación por medio del pin en un mapa, abre esta aplicación en un computador de escritorio.',
      addressPlaceHolder: 'Ingrese la dirección',
      writeLocation: 'Escribe la dirección aquí',
      latitude: 'Latitud',
      longitude: 'Longitud',
      countryName: {
        co: 'Colombia',
        mx: 'México',
      },
      country: 'País',
      state: 'Estado',
      zone: 'Alcaldia',
      city: 'Ciudad',
      neighborhood: 'Colonia',
      address: 'Ubicación',
    },
    contactInfo: {
      name: 'Nombre',
      phone: 'Teléfono',
      email: 'Correo electrónico ',
    },
    statusList: {
      construction: 'Construcción',
      on_delivery: 'En Entrega',
      pre_launch: 'Prelanzamiento',
      completed: 'Finalizado',
      pre_sale: 'Preventa',
      finished: 'Finalizado',
      immediateDelivery: 'Entrega Inmediata',
    },
    projectSubType: {
      default: 'No aplica',
      fraction_vacation_rent: 'Fracción Vacación y Renta',
      fraction_investment: 'Fracción Inversión',
    },
    businessTags: {
      lh_fraction: 'La Haus Fraction',
      lh_pay: 'La Haus Pay',
      ims: 'La Haus IMS',
      lh_insights: 'La Haus Insights',
      lh_marketing_agency: 'La Haus Marketing Agency',
      lh_audiovisual_content: 'La Haus Audiovisual Content',
      masterbroker: 'Master Broker',
      masterlauncher: 'La Haus Launcher',
      lh_push: 'La Haus Push',
      marketplace: 'Marketplace',
      verified_lh: 'La Haus Verificado',
      vis_mexico: 'VIS México',
    },
    businessLines: {
      empty: 'Ninguno',
      marketplace: 'Marketplace',
      exclusives: 'Lanzamiento',
      forSaleByOwner: 'Venta por el propietario',
      rentals: 'Rentas',
    },
    form: {
      currency: 'Tipo de moneda',
      enterprise: 'Empresa',
      catalogId: 'Cod. LH',
      allyLh: 'Aliado LH',
      projectName: 'Nombre del desarrollo',
      placeholderProjectName: 'Escríbelo como lo verán los compradores',
      location: 'Ubicación',
      placeholderLocation: 'Ingresa la dirección exacta',
      kindOfProperty: 'Tipo de propiedad',
      status: 'Estado',
      projectSubtype: 'Subtipo',
      businessTags: 'Tags del negocio',
      businessLine: 'Línea de negocio',
      releasedAt: 'Fecha de lanzamiento',
      timeResponseCompany: 'Tiempo de respuesta del aliado en horas',
      optionsKindOfProperty: {
        houses: 'Casas',
        apartments: 'Departamentos',
        lot: 'Lotes',
        houseAndApartment: 'Casas y departamentos',
      },
      dateOfDelivery: 'Fecha de entrega',
      helpTextDateOfDelivery: 'Esta es una fecha aproximada para el cálculo de mensualidades.',
      description: {
        label: 'Descripción',
        placeholder: 'Ingrese la descripción del desarrollo',
      },
      brief: 'Brochure:',
      projectPhase: 'Fase del desarrollo',
      projectPhaseItems: {
        updatedAt: 'Fecha de actualización',
      },
      descriptionProjectPhase: 'Descripción de la fase del desarrollo',
      selectProjectPhase: 'Selecciona la fase del desarrollo',
      finishes: {
        label: 'Tipo de acabado',
        placeholder: 'Ingrese el nombre del acabado del desarrolo',
      },
      finishesDescription: {
        label: 'Descripción del acabado',
        placeholder: 'Ingrese la descripción del acabado del desarrollo',
      },
    },
    financialInfo: {
      separationLh: 'Valor del apartado LH',
      separationLhError: 'El valor debe ser 0 o mayor a {limitValue}',
      separation: 'Valor del apartado',
      fiduciary: 'Fiduciaria',
      discountAction: '{action} tasa de descuento',
      surchargeAction: '{action} tasa de recargo',
      discounts: 'Descuentos',
      surcharges: 'Recargos',
      addDiscount: 'Agregar descuento',
      addSurcharge: 'Agregar recargo',
      developmentRates: 'Tasas del desarrollo',
      headers: {
        applyFrom: 'Aplica desde',
        type: 'Tipo',
        rate: 'Tasa',
        options: 'Opciones',
      },
    },
  },
  formInventory: {
    form: {
      name: 'Nombre',
      area: 'Área',
      price: 'Precio',
      from: 'Precio desde',
      until: 'Precio hasta',
      parking: 'Estacionamientos',
      bedrooms: 'Habitaciones',
      bathrooms: 'Baños',
      usefulRoom: 'Cuarto útil',
      totalUnits: 'Total unidades',
      placeholders: {
        select: 'Seleccionar',
        from: '500.000',
        until: '1.000.000',
        name: 'Tipología 1',
        area: '60',
        totalUnits: '120',
      },
    },
    toast: {
      addError: 'Ha ocurrido un error al agregar la tipología.',
      addSuccess: 'Se ha agregado la tipología con éxito',
      editError: 'Ha ocurrido un error al editar la tipología.',
      editSuccess: 'Se ha editado la tipología con éxito',
    },
  },
  listInventory: {
    linkExternalInventory: 'Link de lista de precios externa',
    typology: 'Tipología',
    title: 'Inventario',
    description: 'Crea tipologías según la disponibilidad de tu desarrollo.',
    descriptionEdit: 'Edita tipologías según la disponibilidad de tu desarrollo.',
    addNew: 'Agregar nueva tipología',
    deleteError: 'Ha ocurrido un error al eliminar la tipología.',
    publishSuccess: 'Se ha publicado la tipología con éxito',
    deleteSuccess: 'Se ha eliminado la tipología con éxito',
    deleteModalTitle: 'Confirmar eliminación',
    deleteModalDescription: 'Esta acción no se puede deshacer. ¿Estás seguro de eliminar esta tipología?',
    partial: 'Inventario parcial',
    complete: 'Inventario completo',
    changeInventoryModalDescription: '¿Estás seguro que deseas cambiar este desarrollo a {inventoryType}?',
    changeInventoryTypeSuccess: 'Se ha actualizado la información',
    changeInventoryTypeSuccessDescription: 'Se ha actualizado la información con éxito',
  },
  inventoryLoader: {
    title: 'Inventario completo',
    description: 'Crea o actualiza el inventario completo de propiedades, cuartos útiles y estacionamientos',
    viewPriceList: 'Ver lista de precios',
    label: 'Actualiza o crea una lista de precios de propiedades',
    download: 'Descarga la plantilla de lista de precios',
    inputFile: {
      placeholder: 'Arrastra y suelta un archivo',
      button: 'Escoger archivo',
    },
    toast: {
      confirmationFailed: {
        title: 'Confirmación fallida',
        description: 'Ha ocurrido un error confirmando el archivo subido.',
      },
      listWithObservations: {
        title: 'Lista con observaciones',
        description: 'La lista cargada tiene errores carga una nueva corrigiendo los errores',
      },
      onlyImportButNotRead: {
        description: 'El archivo se ha guardado y está disponible, aunque lamentablemente no se pudo llevar a cabo la lectura ni la creación automática de tipologías. Será necesario crearlas manualmente.',
      },
    },
    headers: {
      notes: 'Observaciones',
      name: 'Nombre',
      list: 'Lista',
      user: 'Usuario',
      date: 'Fecha',
    },
  },
  inventory: {
    changeStatus: {
      title: 'Visualización de inventario',
      description: 'Aquí podrá activar o bloquear la visualización del contenido de la página de inventario.',
      active: 'activado',
      blocked: 'bloqueado',
      successChangeStatus: {
        title: 'Se ha {status} el inventario',
        description: 'El inventario ha sido {status} exitosamente',
      },
      banner: {
        title: 'Actualización de inventario',
        description: 'Actualmente se están realizando modificaciones en el inventario. Te recomendamos volver a acceder más tarde.',
      },
    },
    typology: {
      availableTag: 'Disponibles {available}/{total}',
      details: '{area} m² · {rooms} Rec · {bathrooms} Bañ',
      minPrice: 'Desde {price}',
      maxPrice: 'Hasta {price}',
    },
    properties: 'Propiedades',
    updatedAt: 'Última actualización: {date}',
  },
  dataSheet: {
    status: {
      prelaunch: 'Prelanzamiento',
      active: 'Activo',
      inactive: 'Inactivo',
      demo: 'Demo',
      draft: 'Borrador',
      construction: 'Construcción',
      onDelivery: 'En entrega',
      completed: 'Finalizado',
      preSale: 'Preventa',
      finished: 'Finalizado',
      immediateDelivery: 'Entrega inmediata',
    },
    plpStatus: 'Estado PLP',
    availableUnit: '{n} disponible | {n} disponibles',
    undefined: 'No definida',
    property: {
      areas: 'Áreas',
      deliverAt: 'Entrega',
      name: 'Departamento',
      id: 'ID',
      bedrooms: 'Recámaras',
      bedroomShort: 'rec',
      bathrooms: 'Baños',
      unitModel: 'Tipo de depto',
      propertyShort: 'Deptos',
      basicData: 'Datos Básicos',
      priceData: 'Precio',
      areaData: 'Área',
      amenitiesData: 'Habitaciones y baños',
      extraInfo: 'Información adicional',
      parkingLotData: 'Estacionamientos',
      parkingLot: 'Estacionamiento',
      storageRoomData: 'Bodega',
      storageRoomsData: 'Bodegas',
      destroy: 'Eliminar propiedad',
      destroyDescription: 'Esta opción eliminará la propiedad, sus estacionamientos y cuartos útiles',
    },
    technicalSpecifications: {
      releasedAt: 'Fecha de lanzamiento',
      deliveredAt: 'Fecha de entrega',
      finishes: 'Tipo de acabados',
      kamUser: 'Contacto del desarrollo (KAM)',
      administrationPrice: 'Costo de mantenimiento',
      fiduciary: 'Fiduciaria',
      paymentPlans: 'Planes de pago disponibles',
      propertiesCount: 'Total de propiedades',
      parkingCount: 'Total de estacionamientos',
      plpOperationManagerUser: 'Project Manager',
      amenities: 'Áreas Comunes',
      monthlyPaymentEndDate: 'Fecha de fin de pago cuota inicial',
      businessConditionsCommissions: 'Escalamiento de comisiones',
      dateOfVerification: 'Última verificación de precios',
      verifierUser: 'Usuario verificador',
      propertiesByLevel: 'Unidades por piso',
      minimumIncome: 'Ingresos mínimos',
      furnishing: 'Amueblamiento',
      viewsAvailable: 'Vistas disponibles',
      lifestyle: 'Estilo de vida',
      since: 'Desde',
      forInvestors: 'Para inversionistas',
      contactInfo: 'Información de contacto',
      shortRents: 'Rentas cortas',
      sqmValueZone: 'Valor del metro cuadrado de la zona',
      sqmValueProject: 'Valor del metro cuadrado del desarrollo',
      investmentRecoveryTime: 'En cuánto tiempo se recupera la inversión',
      zoneAppreciation: 'Valorización de la zona',
      rentPriceInSector: 'Precio de renta en el sector',
      touristLicense: 'Licencia turística',
      hotelProject: 'Desarrollo hotelero',
      startDateOfOperation: 'Fecha de inicio de operación',
      estimatedProfitability: 'Rentabilidad estimada',
      operatingCost: 'Costo de operación',
      allowsOtherOperators: 'Permite otros operadores',
      howProfitabilityPaidToBuyer: 'Cómo se paga la rentabilidad al comprador',
      expectedOccupancy: 'Ocupación esperada',
      allYouNeedToKnow: 'Lo que debes saber',
    },
  },
  answers: {
    yes: 'Sí',
    no: 'No',
  },
  show: {
    more: 'Mostrar más',
    less: 'Mostrar menos',
  },
  admin: {
    users: {
      forms: {
        edit: 'Editar usuario',
        new: 'Crear usuario',
        toastTitle: 'Información enviada correctamente',
        toastDescription: 'La información se guardo correctamente',
      },
      toast: {
        delete: {
          titleError: 'Error al eliminar el usuario',
          titleSuccess: 'Usuario eliminado con éxito',
        },
      },
      title: 'Gestión de usuarios',
      firstName: 'Primer nombre',
      lastName: 'Apellido',
      name: 'Nombre',
      email: 'Correo electrónico',
      role: 'Rol',
      status: 'Estado',
      phone: 'Teléfono',
      actions: 'Acciones',
      enterpriseName: 'Empresa',
      selectEnterprise: 'Selecciona o busca una empresa',
      statuses: {
        active: 'Activo',
        inactive: 'Inactivo',
      },
      titleModal: '¿Está seguro que desea eliminar este usuario?',
    },
  },
  priceEscalation: {
    rules: 'Reglas de escalamiento de precios',
    add: {
      fromLabel: 'A partir de',
      fromHelperText: 'Cantidad de inmuebles necesarios para activar la regla',
      statusLabel: 'Estado',
      statusHelperText: 'Los inmuebles con los estados seleccionados son los que contarán para activar la regla.',
      typologyLabel: 'Tipología',
      typologyHelperText: 'Las tipologías seleccionadas son las que contarán para activar la regla.',
      typologyOrNomenclatureLabel: 'Tipología o nomenclatura',
      typologyOrNomenclatureHelperText: 'Escoge los departamentos por tipo o nomenclatura a los cuales deseas modificar el precio.',
      valueLabel: 'Tipo y valor',
      valueHelperText: 'El valor a incrementar puede ser por porcentaje, precio fijo o m² dependiendo del área construida.',
      importantInformationTitle: 'Información importante',
      importantInformationDescription: 'Es importante que tengas en cuenta que al crear la regla de escalamiento se recalculara el precio de venta de todas las propiedades en estado Disponible, incluyendo aquellas que cambien a Disponible en el futuro.',
      importantInformationCheckbox: 'He leído y entiendo esta información',
      optionsByTypologyOrNomenclature: {
        all: 'Todo',
        typology: 'Tipología',
        nomenclature: 'Nomenclatura',
      },
    },
    headers: {
      from: 'A partir de',
      status: 'Estado',
      typology: 'Tipología',
      value: 'Valor',
      typologyOrNomenclature: 'Tipología o nomenclatura',
      actions: 'Acciones',
    },
    tooltips: {
      from: 'Cantidad de inmuebles necesarios para activar la regla.',
      typology: 'Inmuebles con estas tipologías son los que se van a contar para activar la regla.',
      value: 'Valor a incrementar puede ser el tipo % se incrementa en un porcentaje tipo $ se incrementa un valor fijo y tipo $/m² se incrementa un valor fijo dependiendo del área construida.',
      typologyOrNomenclature: 'Escoge los departamentos por tipo o nomenclatura a los cuales deseas modificarles el precio.',
    },
    all: 'Todos',
    toast: {
      successAdd: 'Regla de escalamiento agregada con éxito',
      successDelete: 'Regla de escalamiento eliminada con éxito',
      errorList: 'Ha ocurrido un error al cargar la lista de escalamiento de precios',
      errorAdd: 'Ha ocurrido un error al agregar la regla',
      errorDelete: 'Ha ocurrido un error al eliminar la regla',
    },
    deleteModal: {
      title: '¿Estás seguro de eliminar la regla de escalamiento?',
      description: 'Es crucial que consideres que al eliminar la regla de escalamiento, se recalcula el precio de venta de todas las propiedades en estado Disponible, incluso aquellas que en el futuro cambien su estado a Disponible.',
      confirmationCheckbox: 'He leído y entiendo esta información',
    },
    banner: {
      updatingPrices: 'Actualización de precios en curso...',
      updatedPrices: 'Es posible que hayan cambiado los precios. Por favor recargue para ver los precios actualizados.',
    },
  },
  property: {
    types: {
      apartment: 'Departamento',
      duplex: 'Duplex',
      house_and_apartment: 'Casas y Departamentos',
      house: 'Casa',
      loft: 'Loft',
      lot: 'Terreno',
      multifamily_house: 'Casa Multifamiliar',
      office_studio: 'Ofiestudio',
      office: 'Oficina',
      penthouse: 'Penthouse',
      single_family_house: 'Casa Unifamiliar',
      studio_apartment: 'Apartaestudio',
      studio: 'Estudio',
      suite: 'Suite',
      townhouse: 'Townhouse',
      two_family_house: 'Casa Bifamiliar',
      warehouse: 'Local',
      land_single_family: 'Lote unifamiliar',
      land_multi_family: 'Lote bifamiliar',
      apartment_suite: 'Apartasuite',
      unit: 'Unidad',
      fraction: 'Fracción',
    },
    labels: {
      details: 'Detalles',
      alerts: 'Alertas',
      alert: 'Alerta',
      changeState: 'Cambiar estado',
    },
    status: {
      available: 'Disponible',
      reserved: 'Reservado',
      separated: 'Separado',
      sold: 'Vendido',
      nonavailable: 'No disponible',
      non_available: 'No disp',
      filtered: 'Desconocido',
      construction: 'Construcción',
      on_delivery: 'En Entrega',
      pre_launch: 'Prelanzamiento',
      completed: 'Completado',
    },
    parkingType: {
      simple: 'Individual',
      double: 'Doble',
      simple_covered: 'Individual cubierto',
      simple_servitude: 'Individual con servidumbre',
      simple_duplicator: 'Individual con elevautos',
      double_covered: 'Doble cubierto',
      simple_discovered: 'Individual descubierto',
      double_discovered: 'Doble descubierto',
      double_linear: 'Doble lineal',
      motorcycle: 'Moto',
      double_battery: 'Compartido con 1',
      triple: 'Triple',
      triple_battery: 'Compartido con 2',
    },
    quotation: {
      client: 'Cliente',
      defaultNote: 'Esta cotización tiene vigencia de 3 días. El valor del inmueble no incluye costos adicionales, como: Notaria, impuestos de registro, inscripción en registro, estudio de títulos, seguros, certificados, impuestos, contribuciones, cuotas de administración, ni servicios públicos. Las condiciones del negocio pueden ser modificadas por la sociedad que desarrolla el desarrollo, sin previo aviso, antes del pago de la {fee}.',
      initial_fee_percentage: 'Enganche',
      whatsappMessage: 'Hola {firstName} para descargar la cotización ingresa al siguiente enlace {url}',
      customer: {
        email: 'Correo electrónico',
        newEmail: 'Enviar también a:',
        emailPlaceholder: "usuario{'@'}mail.com",
        firstName: 'Nombre',
        firstNamePlaceholder: 'Nombre completo',
        lastName: 'Apellido',
        lastNamePlaceholder: 'Apellido completo',
        celphone: 'Número de celular',
        add: 'Agregar cliente',
        delete: 'Retirar cliente de la cotización',
        toast: {
          notFound: 'El cliente no existe',
          notFoundDescription: 'Se mostrará el formulario de creación',
        },
      },
      send: {
        title: 'Enviar propuesta',
        email: 'Correo',
        whatsapp: 'Whatsapp',
        download: 'Descargar',
        addOther: 'Agregar otro correo',
        emails: 'Otros correos',
        wpTextLabel: 'Aquí verás la plantilla que podrás copiar y enviar a los clientes',
        wpTextPlaceholder: 'Hola, adjunto a este mensaje encontrarás el link de la cotización generada...',
        downloadText: 'Descarga la cotización',
        button: {
          email: 'Enviar para aprobación',
          whatsapp: 'Generar plantilla',
          download: 'Descargar cotización',
        },
        languageSelector: 'Selecciona el idioma que quieres utilizar',
        forClient: 'Enviar al cliente',
      },
      header: {
        title: 'Cotización de Cliente',
      },
      toast: {
        copyTextToClipboard: 'La plantilla ha sido copiada',
        fileDownloaded: 'Archivo descargado',
        operationSuccess: 'Operación realizada con éxito',
        quotationSuccess: 'La cotización se generó correctamente',
        quotationError: 'Error al enviar cotización',
      },
      successMessageQuotationType: {
        quotation: 'La cotización se generó correctamente',
        payment_plan: 'El plan de pagos se generó correctamente',
      },
      message: {
        noAvailableQuotation: 'Cotización no disponible',
        noAvailableState: 'El estado en el que se encuentra la propiedad no permite realizar cotizaciones',
        noAssociatedPlan: 'La propiedad no cuenta con un plan de pagos asociado para cotizar',
        noPlan: 'El proyecto no cuenta con planes de pagos configurados para cotizar',
      },
    },
    buttons: {
      approve: 'Aprobar',
      reject: 'Rechazar',
      cancel: 'Cancelar',
      save: 'Guardar cambios',
      respond: 'Responder',
    },
    alert: {
      date: 'Fecha',
      description: 'Descripción',
      state: 'Estado',
      disclaimer: 'Para aceptar o rechazar el cambio ingresa una razón y ejecuta la acción que consideres pertinente.',
      label: 'Razón de cambio',
      placeholder: 'Descripción de la razón del cambio',
      approved: 'Aprobado',
      rejected: 'Rechazado',
      pending: 'Pendiente',
      changedBy: 'Realizado por',
      titlePendingChangeStatus: 'Cambio de estado pendiente enviado el {date}',
      titleRespondChangeStatus: 'Alerta propiedad {property}',
      response: {
        label: 'Motivo de aprobación o rechazo *',
        placeholder: 'Escribe el motivo por el cual se aprueba o rechaza el cambio de estado',
      },
    },
    changeState: {
      status: 'Estado de la propiedad',
      whoAuthorize: '¿Quién autoriza el cambio?',
      whoReserves: 'A nombre de quién',
      whoReservesEmail: 'Correo de la persona',
      reserveSeparation: 'Reserva/separación hasta',
      changeReason: 'Razón del cambio',
      finalPrice: 'Precio final',
      date: 'Fecha',
      cannotChangeState: {
        title: 'Cambio de estado',
        description: 'La propiedad no se puede cambiar de estado porque el desarrollo se encuentra en prelanzamiento',
      },
      labels: {
        email: 'Correo del cliente',
        firstName: 'Nombre del cliente',
        lastName: 'Apellido del cliente',
        whoAuthorize: 'Quién autoriza el cambio',
        reserveTime: 'Tiempo de reserva/separación',
        parkings: 'Estacionamientos',
        storageRooms: 'Bodegas',
        businessPrice: 'Valor del negocio',
      },
      placeholders: {
        firstName: 'Nombre',
        lastName: 'Apellido',
        whoAuthorize: 'Nombre completo',
      },
    },
    toast: {
      changeAlert: {
        approved: 'La alerta fue aprobada',
        rejected: 'La alerta fue rechazada',
      },
      clearInventory: {
        success: 'El inventario fue limpiado con éxito.',
        error: 'Error al limpiar el inventario.',
      },
      changeState: 'Se cambió el estado de la propiedad',
      edit: 'Se editó correctamente la propiedad',
      destroy: 'Se eliminó correctamente la propiedad',
    },
    placeValue: 'Valor de la vivienda',
    basePrice: 'Precio base de escalamiento:',
    id: 'ID de la propiedad',
    currentStatus: 'Estado actual',
    information: 'Información general',
    typology: 'Tipología',
    totalArea: 'Área total',
    viewType: 'Tipo de vista',
    amenitiesData: 'Recamaras y baños',
    bathrooms: 'Baños',
    bedrooms: 'Recamaras',
    soldByLh: 'Vendido por La Haus',
    parkingsQuantity: 'Cantidad',
    parkingsType: 'Tipo',
    storageRoomValue: 'Valor',
    storageRoomArea: 'Área de bodega',
    edit: 'Editar propiedad',
    destroy: 'Eliminar propiedad',
    destroyConfirmation: 'Estás a punto de eliminar una propiedad y esta acción no se puede deshacer. ¿Estás seguro de que deseas continuar?',
    linkCheckout: 'Reservar propiedad desde checkout',
    text: 'Propiedad',
    details: 'Detalles',
    editForm: {
      basicData: 'Datos básicos',
      labels: {
        propertyType: 'Tipo de propiedad',
        level: 'Sección/Piso',
        totalPrice: 'Precio total',
        description: 'Descripción',
        nomenclature: 'Nomenclatura',
        parkings: 'Estacionamientos',
        storageRooms: 'Bodegas',
      },
      extraSelected: '{count} seleccionados | {count} seleccionado | {count} seleccionados',
    },
  },
  cms: {
    collectionName: 'Nombre de la colección',
    title: 'CMS',
    subtitle: 'Crea y edita contenido para tu desarrollo',
  },
  trustFundLinking: {
    title: 'Formulario único de vinculación',
    sections: {
      metadata: 'Metadatos',
      identification: 'Datos de identificación',
      personalData: 'Datos personales',
      contactData: 'Datos de contacto',
      occupationType: 'Tipo de ocupación',
      occupationData: 'Datos de ocupación',
      financialBalance: 'Balance financiero',
      fundsOrigin: 'Origen de recursos',
      internationalResponsibilities: 'Responsabilidades internacionales',
      internationalTaxes: 'Impuestos internacionales',
      additionalData: 'Datos adicionales',
    },
    form_fields: {
      version: 'Versión',
      uuid: 'UUID de vinculación',
      reserve_uuid: 'Id de reserva',
      cdp_id: 'CDP id',
      is_completed: '¿Formulario completo?',
      first_name: 'Primer nombre',
      middle_name: 'Segundo nombre',
      last_name: 'Primer apellido',
      second_last_name: 'Segundo apellido',
      nationality: 'Nacionalidad (País)',
      birth_date: 'Fecha de nacimiento',
      identification_type: 'Tipo de documento',
      identification_number: 'Número de documento',
      document_issue_country: 'País de expedición',
      document_issue_state: 'Departamento de expedición',
      document_issue_city: 'Ciudad de expedición',
      document_issue_date: 'Fecha de expedición',
      birth_country: 'País de nacimiento',
      birth_state: 'Departamento de nacimiento',
      birth_city: 'Ciudad de nacimiento',
      gender: 'Género',
      marital_status: 'Estado civil',
      address: 'Dirección',
      neighborhood: 'Barrio',
      stratum: 'Estrato',
      city: 'Ciudad',
      state: 'Departamento',
      country: 'País',
      phone_prefix: 'Prefijo de teléfono',
      phone_number: 'Número de teléfono',
      email: 'Correo electrónico',
      person_type: 'Tipo de persona',
      person_sub_type: 'Tipo de ocupación',
      employment_contract: 'Tipo de contrato',
      occupation: 'Ocupación',
      ciiu_code: 'Código CIIU',
      education_level: ' Nivel de escolaridad',
      education_specialization: 'Especialización',
      business_name: 'Razón social',
      tax_identification_number: 'Número de identificación tributaria',
      job_company_name: 'Nombre de la empresa donde trabaja',
      job_title: 'Cargo u oficio',
      job_company_country: 'País de empresa donde trabaja',
      job_company_city: 'Ciudad de empresa donde trabaja',
      job_company_address: 'Dirección de empresa donde trabaja',
      job_company_phone_number: 'Número de teléfono de empresa donde trabaja',
      declares_incomes: '¿Declara renta?',
      total_assets: 'Total activos',
      total_passives: 'Total pasivos',
      monthly_income: 'Ingresos mensuales',
      monthly_expenses: 'Gastos mensuales',
      financial_balance_currency_type: 'Tipo de moneda del balance financiero',
      has_other_incomes: '¿Tiene otros ingresos?',
      other_incomes: 'Otros ingresos',
      other_incomes_detail: 'Detalle de otros ingresos',
      other_incomes_currency_type: 'Tipo de moneda de otros ingresos',
      funds_origin: 'Origen de recursos',
      is_international_legal_representative: '¿Es representante legal de una organización internacional?',
      international_organization_name: 'Nombre de la organización internacional',
      carries_out_international_ops: '¿Realiza operaciones en el extranjero?',
      international_op_country: 'País con el que realiza operaciones internacionales',
      international_op_transaction_type: 'Tipo de transaccion de operación internacional',
      international_op_financial_entity: 'Entidad financiera con la que realiza operaciones internacionales',
      international_op_product_type: 'Tipo de producto de operación internacional',
      international_op_product_id: 'Número de identificación de producto de operación internacional',
      international_op_currency_type: 'Tipo de moneda de operación internacional',
      international_op_average_monthly_value: 'Ingreso promedio mensual por operación internacional',
      usa_tax_identification_number: 'TIN de estados unidos',
      is_public_or_politically_exposed: '¿Es una persona pública o politicamente expuesta?',
      position_or_function_performed: 'Cargo desempeñado',
      position_start_date: 'Fecha de inicio del cargo',
      position_end_date: 'Fecha de terminación del cargo',
      is_current_position: 'Es un cargo actual',
      has_conjugal_partnership_with_exposed_person: '¿Tiene sociedad conyugal con una persona pública o politicamente expuesta?',
      spouse_name: 'Nombre del cónyuge',
      spouse_relationship: 'Parentesco del cónyuge',
      spouse_position: 'Cargo del cónyuge',
      additional_tax_country: 'País adicional de tributación',
      additional_tax_identification_number: 'TIN de país adicional de tributación',
      createdAt: 'Fecha de creación',
      updatedAt: 'Fecha de última actualización',
      publishedAt: 'Fecha de publicación',
      is_taxpayer_in_usa: '¿Tributa en Estados Unidos?',
      is_taxpayer_in_other_countries: '¿Tributa en otros países?',
      accept_terms_and_conditions: '¿Acepta términos y condiciones?',
    },
    form_values: {
      gender: {
        MALE: 'Masculino',
        FEMALE: 'Femenino',
      },
      person_type: {
        NATURAL: 'Natural',
        LEGAL: 'Jurídica',
        MORALE: 'Moral',
        PHYSICAL: 'Física',
      },
      person_sub_type: {
        EMPLOYEE: 'Empleado',
        FREELANCER: 'Independiente',
        RETIRED: 'Pensionado',
        NO_APPLY: 'No aplica',
      },
      employment_contract: {
        FULL_TIME: 'Indefinido',
        FIXED: 'Término fijo',
        HOURLY: 'Obra labor',
      },
      education_level: {
        BACHELORS_DEGREE: 'Bachiller',
        TECHNICAL_DEGREE: 'Técnico',
        PROFESSIONAL: 'Profesional',
        GRADUATE_DEGREE: 'Posgrado',
      },
      education_specialization: {
        MASTER: 'Maestría',
        PHD: 'Doctorado',
      },
      marital_status: {
        SINGLE: 'Soltero',
        DOMESTIC_PARTNERSHIP: 'Unión libre',
        MARRIED: 'Casado',
        WIDOWED: 'Viudo',
        DIVORCED: 'Divorciado',
      },
      international_op_transaction_type: {
        CURRENCY_EXCHANGE: 'Cambio de divisas',
        EXPORTS: 'Exportaciones',
        INTERNATIONAL_TRANSFERS: 'Giros internacionales',
        IMPORTS: 'Importaciones',
        INVESTMENTS: 'Inversiones',
        PAYMENT_OF_SERVICES: 'Pago de servicios',
        FOREIGN_CURRENCY_LOANS: 'Préstamos en Moneda Extranjera',
      },
    },
  },
  baseInput: {
    enterValue: 'Ingresa un valor',
    selectTypeValue: 'Selecciona el tipo de valor (% / $)',
    rejectPlanReason: '¿Cuál es el motivo de no aprobar el plan de pagos?',
    editPlanReason: '¿Cuál es el motivo de modificar el plan de pagos?',
  },
  modals: {
    addMilestones: 'Agregar condición de pago',
    editMilestones: 'Editar condición de pago',
    deleteMilestone: 'Eliminar condición de pago',
    confirmDeleteMilestone: '¿Está seguro que desea eliminar la condición de pago?',
    deleteCommission: 'Eliminar escalamiento de comisión',
    confirmDeleteCommission: '¿Está seguro que desea eliminar el escalamiento de comisión?',
    nomenclatureModalTitle: 'Lista de nomenclaturas',
    nomenclatureModalDescription: 'Nomenclaturas para las que aplica esta regla.',
    editReason: 'Motivos de la edición',
    rejectedReason: 'Motivos para no aprobar el plan',
    editPlan: 'Editar Plan',
    approveDescription: 'Al aprobar el plan de pagos, se notificará al agente de La Haus encargado de este trato para que pueda continuar con la venta del inmueble.',
    planApprovedDescription: 'Se envió un correo a {client} para poder continuar con el proceso de compra.',
    planEditedDescription: 'Se enviará un correo a {client} notificando que el plan de pagos ya aprobado ha sido editado.',
    planRejectedDescription: 'Se envió un correo a {client} proponiendo realizar ajustes en el plan de pagos de acuerdo a sus comentarios y sugerencias.',
    rejectWarning: '¿Estás seguro de no aprobar el plan de pagos?',
    rejectPlanReason: '¿Cuál es el motivo de no aprobar el plan de pagos?',
    editPlanReason: '¿Cuál es el motivo de modificar el plan de pagos?',
    approveTitleModal: 'Aprobar plan de pagos',
    rejectTitleModal: 'No aprobar plan de pagos',
    editPlanTitleModal: 'Envia propuesta de plan de pago',
    planApprovedTitle: '¡Plan de pago aprobado!',
    planRejectedTitle: '¡Plan de pago no aprobado!',
    planRejectedError: 'No fue posible rechazar el plan',
    planApprovedError: 'No fue posible aprobar el plan',
    approveEditTitle: 'Editar plan de pagos',
  },
  commissions: {
    selectAll: 'Todas las opciones han sido seleccionadas',
    fixedCommission: 'Comisión fija',
    salesRank: 'Rango de venta',
    tableHeader: {
      retroactive: 'Retroactivo',
      all: 'Todas',
      nomenclature: 'Nomenclatura',
      from: 'Desde',
      to: 'Hasta',
      commission: 'Comisión',
      commissionPremium: 'Comisión premium',
      commissionEI: 'Comisión EI',
      iva: 'IVA',
      soldFrom: 'Desde (unid)',
      soldTo: 'Hasta (unid)',
      actions: 'Acciones',
    },
    modal: {
      title: 'Información importante',
      description: '¿Estás seguro de continuar? Es importante que tengas en cuenta que a través de esta acción puedes afectar el escalamiento de comisiones. Cuando confirmes, se ejecutarán una serie de procesos para volver a calcular las comisiones de este desarrollo.',
      button: 'He leído y entiendo esta información',
      form: {
        rule_all: 'Aplica a todas las unidades?',
        addCommissions: 'Añadir regla de comisión',
        editCommission: 'Editar regla de comisión',
        retroactively: 'Aplica retroactivo',
        nomenclature: {
          label: 'Nomenclatura',
          placeholder: 'Selecciona los que apliquen',
          helperText: 'Selecciona las nomenclaturas en las que aplica esta regla.',
        },
        dates: {
          label: 'Fechas',
          helperText: 'Ingresa las fechas en las que aplica esta regla utilizando el formato DD/MM/YYYY.',
          from: 'Desde',
          to: 'Hasta',
        },
        commissions: {
          label: 'Comisión',
          helperText: 'Agrega la comisión que aplica para esta regla.',
        },
        iva: {
          label: 'IVA',
          helperText: 'Indica el porcentaje de IVA que aplica.',
        },
        sold: {
          label: 'Unidades',
          helperText: 'Indica para cuantas unidades aplica la regla.',
          from: 'Desde',
          to: 'Hasta',
        },
        commissionsPremium: {
          label: 'Comisión premium',
          helperText: 'Agrega la comisión que aplica para esta regla.',
        },
        commissionsEI: {
          label: 'Comisión del especialista inmobiliario',
          helperText: 'Agrega la comisión que aplica para esta regla.',
        },
      },
    },
    toast: {
      updateCommission: {
        title: 'Escalamiento de comisión actualizada',
        description: 'Se actualizó correctamente el escalamiento de comisiones',
      },
      deleteCommission: {
        title: 'Escalamiento de comisión eliminadas',
        description: 'Se eliminó correctamente el escalamiento de comisiones',
      },
    },
  },
  affirmations: {
    yes: 'Sí',
    no: 'No',
  },
  errors: {
    title: 'Ha ocurrido un error',
    propertyStatusDoesNotMatch: 'El estado de la propiedad no coincide',
    checkout: {
      reserve: 'Error al obtener la información de la reserva en Checkout',
    },
    listing: {
      search: 'No se pudieron obtener los desarrollos desde Listing',
    },
    cdp: {
      search: 'No se pudo obtener la información del usuario desde CDP',
    },
    fintech: {
      authApi: 'Error al establecer la comunicación con Fintech',
      paymentCards: '',
      createDocument: 'Error al crear el documento en Fintech',
      updateDocument: 'Error al actualizar el documento en Fintech',
      deleteDocument: 'Error al eliminar el documento en Fintech',
      documentsConfig: 'Error al obtener la configuración de documentos en Fintech',
    },
    locations: {
      search: 'Error al buscar la ubicación',
    },
    projectIsNotPartial: 'El desarrollo no maneja inventario parcial',
    propertyNotFound: 'Propiedad no encontrada',
    enterpriseNotFound: 'Empresa no encontrada',
    projectNotFound: 'Desarrollo no encontrado',
    quotationNotFound: 'Cotización no encontrada',
    userNotFound: 'Usuario no encontrado',
    invalidAddDiscountsByUserRole: 'El rol del usuario no le permite agregar descuentos',
    invalidProposedPaymentPlan: 'El plan propuesto por el usuario no es válido',
    quotationClientNotFound: 'Cliente no encontrado',
    quotationParamsBlank: 'Los parámetros enviados no son válidos',
    paymentPlanConfigBlank: 'La cotización no tiene un plan de pagos configurado',
    couldNotCreatePaymentPlan: 'No se pudo crear el plan de pagos',
    invalidPaymentPlanRestrictionParams: 'Parámetros no válidos para la restricción de plan de pagos',
    changeStateIsNotPermitted: 'No se puede cambiar el estado de la propiedad',
    noNeedProcessChangeState: 'No se puede realizar cambio de estado',
    changeStateAlertNotFound: 'No se encontró la alerta de cambio de estado',
    couldNotUpdatePaymentPlan: 'No se pudo actualizar el plan de pagos',
    couldNotUpdateUser: 'No se pudo actualizar el usuario',
    couldNotCreateTypologySet: 'No se pudo crear la tipología',
    couldNotUpdateTypologySet: 'No se pudo actualizar la tipología',
    couldNotCreateTypologySetDraft: 'No se pudo crear la tipología en borrador',
    couldNotUpdateTypologySetDraft: 'No se pudo actualizar la tipología en borrador',
    couldNotCreateUser: 'No se pudo crear el usuario',
    couldNotCreateTakeRateRules: 'No se pudo crear la regla de escalamiento',
    couldNotUpdateTakeRateRules: 'No se pudo actualizar la regla de escalamiento',
    couldNotUpdateQuotation: 'No se pudo actualizar la cotización',
    accessDenied: 'No tienes permisos suficientes',
  },
  files: {
    dragAndDrop: 'Arrastra y suelta',
    chooseImage: 'Escoger imagen',
    validFormats: 'Formatos permitidos: {format}',
  },
  assets: {
    categories: {
      cover: 'Portada',
      gallery: 'Galería',
      blueprints: 'Planos',
      level: 'Planta',
      unclassified: 'Sin clasificar',
      typology: 'Tipología',
      property_name: 'Unidad',
      bedrooms: 'Habitaciones',
      all_properties: 'Todas las propiedades',
    },
    delete: {
      title: 'Eliminar imagen',
      description: '¿Estás seguro/a que quieres eliminar la imagen "{imageName}"?',
    },
    successfulCreate: {
      title: 'Imagen creada',
      description: 'La imagen fue creada exitosamente',
    },
    successfulDelete: {
      title: 'Imagen eliminada',
      description: 'La imagen fue eliminada exitosamente',
    },
    errorfulDelete: {
      title: 'La imagen no fue eliminada',
      description: 'Error al eliminar la imagen',
    },
    associateTypology: 'Asociar a tipología { typology }',
    markAsCover: 'Marcar como portada',
    markAsGallery: 'Marcar como galeria',
    loaderMessage: 'Cargando imágenes: {imageInProgress} de {totalImages} | Procesando...',
    description: 'Agrega todo el contenido que quieres que vean los compradores para conocer mejor tu desarrollo. Puedes cargar archivos o ingresar links.',
    content: 'Contenido',
    externalLinks: 'Enlaces externos',
    options: {
      label: 'Contenido del enlace',
      video: 'Video',
      tour: 'Tour virtual',
      webinar: 'Webinar',
    },
    links: {
      label: 'Enlaces',
      placeholder: 'www.enlace.com',
      description: 'Descripción',
    },
    toast: {
      successLinks: 'Enlaces actualizados con éxito',
      errorLinks: 'Error al actualizar los enlaces',
      successBrochure: 'Brochure actualizado con éxito',
      errorBrochure: 'Error al actualizar el brochure',
    },
  },
  notifications: {
    selectProject: {
      title: 'Selecciona el desarrollo a configurar',
      placeholder: 'Selecciona o busca un desarrollo',
    },
    title: 'Configuración de notificaciones',
    description: 'Selecciona la notificaciones que quieres recibir por desarrollo. Por defecto, todas las notificaciones están encendidas',
    label: 'Notificaciones',
    emptyList: 'No hay notificaciones para mostrar',
    othersNotifications: {
      title: 'Notificaciones adicionales',
      description: 'Aquí podrás activar las notificaciones adicionales a la notificación del navegador',
    },
    activated: 'Notificaciones activadas',
    deactivated: 'Notificaciones desactivadas',
    message: 'Recibirás una notificación por Whatsapp:',
    pendingApprovalPlan: {
      title: 'Nuevo plan de pagos pendiente por aprobar',
      description: 'Recibirás una notificación del navegador cuando haya un nuevo plan de pagos pendiente por aprobar',
    },
    reminderPendingApprovalPlan: {
      title: 'Recordatorio diario de planes de pagos pendientes por aprobar',
      description: 'Recibirás una notificación diaria del navegador cuando hayan planes de pagos pendientes por aprobar con más de 24 horas de creación',
    },
    documentUpload: {
      title: 'Carga de documentos',
      description: 'Recibirás una notificación del navegador cuando esten cargados los documentos de cada propiedad',
    },
    documentsValidated: {
      title: 'Documentación validada por La Haus',
      description: 'Recibirás una notificación del navegador cuando un especialista de La Haus haya validado la documentación',
    },
    newSeparation: {
      title: 'Nuevas propiedades reservadas',
      description: 'Recibirás una notificación cuando se reserve una propiedad',
    },
    toast: {
      success: {
        title: 'Notificaciones actualizadas',
        description: 'Se actualizaron correctamente las notificaciones',
      },
    },
  },
  backoffice: {
    title: 'Back office La Haus',
    list: {
      syncUp: {
        title: 'Estado de propiedades',
        subtitle: 'Sincroniza el estado de las propiedades',
      },
      projects: {
        title: 'Desarrollos',
        subtitle: 'Consulta desarrollos y versiones',
      },
      sidekiq: {
        title: 'Trabajos encolados (Sidekiq)',
        subtitle: 'Consulta y gestiona los trabajos encolados',
      },
      rsyncOpportunities: {
        title: 'Resincroniza las oportunidades desde el CRM',
        subtitle: 'Trae todas las oportunidades que hayan sido modificadas en el CRM',
        toast: {
          success: 'Se inicio el proceso de actualización de oportunidades',
        },
      },
    },
    tabs: {
      property: 'Propiedad',
      eventLog: 'Bitácora de eventos',
      projects: 'Desarrollos',
    },
    fields: {
      id: 'Id',
      enterprise: 'Empresa',
      project: 'Desarrollo',
      property: 'Propiedad',
      typology: 'Tipología',
      status: 'Estado',
      price: 'Precio',
      externalId: 'Id externo',
      catalogId: 'Id de catálogo',
      sellerId: 'Id de 4Seller',
      country: 'País',
      city: 'Ciudad',
      zone: 'Alcaldia',
      neighborhood: 'Colonia',
      address: 'Dirección',
      numberOfProperties: 'Cantidad de propiedades',
      isPlp: 'Es PLP',
      propertiesWithoutExternalId: 'Propiedades sin external ID',
      propertiesWithoutCatalog: 'Propiedades sin catálogo',
      amountOfReservations: 'Cantidad de reservas / Cambios de estado en IMS',
      amountOfReservesInPLP: 'Cantidad de reservas en PLP',
    },
    actions: {
      modifyPrice: 'Modificar precio',
      projectOptions: 'Opciones del desarrollo',
      editProject: 'Editar desarrollo',
      syncWithCatalog: 'Sincronizar con catálogo',
      syncPropertiesWithCatalog: 'Sincronizar propiedades con catálogo',
      syncPropertiesWithSobreplanos: 'Sincronizar propiedades con sobreplanos',
      changePropertiesToUnavailable: 'Cambiar propiedades a no disponible',
      deleteInventory: 'Borrar el inventario',
      removeProject: 'Eliminar el desarrollo',
      enablePaymentPlanFlow: 'Habilitar el flujo de plan de pagos',
      disablePaymentPlanFlow: 'Deshabilitar el flujo de plan de pagos',
      deleteProjectImages: 'Eliminar imágenes del desarrollo',
    },
    modal: {
      modifyPrice: 'Modificar el precio de la propiedad',
      modifyField: '¿Estás seguro de {action}?',
      reasonToChange: '¿Cuál es la razón del cambio?',
      deleteProjectImagesDescription: "¡Advertencia! Al hacer clic en 'Aceptar', todas las imágenes del desarrollo se eliminarán permanentemente y no podrás deshacer esta acción. Por favor, asegúrate de estar completamente seguro antes de proceder.",
    },
    toast: {
      price: {
        error: {
          title: 'Error al modificar el precio',
        },
        success: {
          title: 'Precio modificado con éxito',
        },
      },
      syncWithCatalog: {
        error: {
          title: 'Error al sincronizar con catálogo',
        },
        success: {
          title: 'Se sincronizó con catálogo con éxito',
        },
      },
      syncPropertiesWithCatalog: {
        error: {
          title: 'Error al sincronizar la propiedad con catálogo',
        },
        success: {
          title: 'Se sincronizó la propiedad con catálogo con éxito',
        },
      },
      syncPropertiesWithSobreplanos: {
        error: {
          title: 'Error al sincronizar la propiedad con sobreplanos',
        },
        success: {
          title: 'Se sincronizó la propiedad con sobreplanos con éxito',
        },
      },
      changePropertiesToUnavailable: {
        error: {
          title: 'Error al cambiar la propiedad a "No disponible"',
        },
        success: {
          title: 'Se cambio la propiedad a "No disponible" con éxito',
        },
      },
      deleteInventory: {
        error: {
          title: 'Error al borrar inventario',
        },
        success: {
          title: 'Se borró el inventario con éxito',
        },
      },
      removeProject: {
        error: {
          title: 'Error al eliminar el desarrollo',
        },
        success: {
          title: 'Se eliminó el desarrollo con éxito',
        },
      },
      enablePaymentPlanFlow: {
        error: {
          title: 'Error al habilitar el flujo de plan de pagos',
        },
        success: {
          title: 'Se habilitó el flujo de plan de pagos con éxito',
        },
      },
      disablePaymentPlanFlow: {
        error: {
          title: 'Error al deshabilitar el flujo de plan de pagos',
        },
        success: {
          title: 'Se deshabilitó el flujo de plan de pagos con éxito',
        },
      },
    },
    headers: {
      date: 'Fecha de registro',
      register: 'Registro',
      entity: 'Entidad',
      itemType: 'Tipo',
      user: 'Usuario',
      event: 'Evento',
      changes: 'Cambios realizados',
    },
    event: {
      update: 'Actualización',
      actionFromAdmin: 'Acción del administrador',
    },
    primaryButton: {
      enable: 'Habilitar',
      disable: 'Deshabilitar',
      accept: 'Aceptar',
    },
    changesMade: {
      extraInfo: 'Información extra',
      enablePaymentPlanFlow: 'Habilitar flujo de pago',
      updatedAt: 'Actualizado en',
      method: 'Método',
      reason: 'Razón',
      externalStatus: 'Estado externo',
      prices: 'Precios',
      price: 'Precio',
      minPrice: 'Precio mínimo',
      videoUrls: 'URLs de video',
      isCompleted: 'Está completado',
      lockedFields: 'Campos bloqueados',
      deliveredAt: 'Entregado en',
      city: 'Ciudad',
      zone: 'Alcaldia',
      state: 'Estado',
      latitude: 'Latitud',
      cityCode: 'Código de ciudad',
      longitude: 'Longitud',
      zoneCode: 'Código de alcaldia',
      stateCode: 'Código de estado',
      neighborhood: 'Colonia',
      neighborhoodCode: 'Código de colonia',
      discountRates: 'Tasas de descuento',
      surchargeRates: 'Tasas de recargo',
      releasedAt: 'Fecha de liberación',
      contactInfo: 'Información de contacto',
      tourUrls: 'URLs de tour',
      monthlyPaymentsAdditions: 'Adiciones a pagos mensuales',
      projectSubtype: 'Subtipo de desarrollo',
      maxPrice: 'Precio máximo',
      businessTags: 'Etiquetas de negocio',
      hasAgreement: 'Tiene acuerdo',
      quotationPlans: 'Planes de cotización',
      agreementPercentage: 'Porcentaje de acuerdo',
      paymentText: 'Texto de pago',
      hasSalesRooms: 'Tiene salas de ventas',
      isShortTermRental: 'Es alquiler a corto plazo',
      numberQmProcessesRunning: 'Número de procesos QM en ejecución',
      constructionInfo: 'Información de construcción',
      totalLotArea: 'Área total del lote',
      totalBuiltArea: 'Área total construida',
      monthlyPaymentStartDate: 'Fecha de inicio del pago mensual',
      condoType: 'Tipo de condominio',
      qmValidationExecutedAt: 'Fecha de ejecución de validación QM',
      salesRoomAttendant: 'Encargado de la sala de ventas',
      financialInfo: 'Información financiera',
      financedBy: 'Financiado por',
      inventoryUpdatedAt: 'Fecha de actualización del inventario',
      description: 'Descripción',
      bathrooms: 'Baños',
      bedrooms: 'Habitaciones',
      name: 'Nombre',
      areas: 'Áreas',
      areaBuild: 'Área construida',
      initialPrice: 'Precio inicial',
      level: 'Nivel',
      stage: 'Etapa',
      tower: 'Torre',
      viewType: 'Tipo de vista',
      grouperName: 'Nombre del agrupador',
      houseFloors: 'Número de pisos de la casa',
      propertyType: 'Tipo de propiedad',
      propertyGrouperId: 'ID del agrupador de propiedades',
      monthlyPaymentEndDate: 'Fecha de fin de pago mensual',
      previousStatus: 'Estado anterior',
      newStatus: 'Nuevo estado',
      whoAuthorize: 'Quién autoriza',
      whoReserves: 'Quién reserva',
      finalPrice: 'Precio final',
      imsUserId: 'Id usuario',
      propertyId: 'Id propiedad',
      whoReservesName: 'Nombre quién reserva',
      whoReservesLastname: 'Apellido quién reserva',
      reserveTime: 'Duración de la reserva',
      data: 'Data',
      sourceChange: 'Fuente del cambio',
      externalData: 'Data externa',
      whoReservesPhone: 'Teléfono quién reserva',
      whoReservesEmail: 'Email quién reserva',
      quotationId: 'Id cotización',
      status: 'Estado',
      createdAt: 'Fecha de creación',
    },
  },
  hubs: {
    cohantioquia: 'Antioquia',
    cohbogota: 'Bogotá',
    cohcaribe: 'Caribe',
    cohvalledelcauca: 'Valle del Cauca',
    mxhciudaddemexico: 'Ciudad de México',
    mxhrivieramaya: 'Riviera Maya',
    mxhjalisco: 'Jalisco',
    mxhqueretaro: 'Querétaro',
    mxhnuevoleon: 'Nuevo León',
    mxhyucatan: 'Yucatán',
  },
};
