// Constants
const FLOW_RESTRICTS = {
  'projects-project-required_documents': 'documentation_config',
  'projects-project-cms': 'cms_config',
  'projects-project-escalations-price_escalation': 'take_rate_config',
  'projects-project-setting': 'tab_config',
  'projects-project-payments': 'payments_config',
};

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { specialistRoleId } = useRuntimeConfig().public;

  if (process.server) {
    return;
  }

  const { $auth, $i18n } = useNuxtApp();
  const isAuthenticated = await $auth.isAuthenticated();

  const paramsAfterNewPassword = from?.query?.success === 'true' && from?.query?.message?.includes('new password');

  if (isAuthenticated && paramsAfterNewPassword) {
    await $auth.logout();
    return;
  }

  if (to.path !== '/' && !isAuthenticated) {
    sessionStorage.setItem('lh-seller-prev-route', from.fullPath);
    return $auth.login();
  }

  if (to.path === '/' && isAuthenticated) {
    return navigateTo('/projects');
  }

  const userStore = useUserStore();

  if (isAuthenticated && !userStore.userInfo.id) {
    await userStore.getUser();
  }

  const showInformationSearchModal = window.localStorage.getItem('show-information-search-modal');
  if (showInformationSearchModal === null) {
    window.localStorage.removeItem('show-information-search-modal');
    const userRoleId = userStore.userInfo?.roleId;
    if (userRoleId && userRoleId === specialistRoleId) {
      window.localStorage.setItem('show-information-search-modal', true);
    }
  }

  if (!userStore.hasAcceptedTerms && to.name !== 'index' && to.name !== 'terms_and_conditions') {
    return navigateTo('/terms_and_conditions');
  }

  const restrict = FLOW_RESTRICTS[to.name];

  if (userStore.userFlowRestricts.includes(restrict)) {
    const toastStore = useToastStore();

    toastStore.show({
      title: $i18n.t('index.toast.flowRestrict.title'),
      description: $i18n.t('index.toast.flowRestrict.description'),
      variant: 'error',
      timeToHide: 8000,
    });

    return navigateTo('/projects');
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'ims-uid': userStore.userInfo.id,
  });
});
