import { createAuth0Client } from '@auth0/auth0-spa-js';

export default defineNuxtPlugin(async () => {
  const {
    auth0Domain,
    auth0ClientId,
    frontendUrl,
    backendUrl,
  } = useRuntimeConfig().public;
  const auth = await createAuth0Client({
    domain: auth0Domain,
    clientId: auth0ClientId,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
      audience: backendUrl,
      scope: 'openid profile user_profile email offline_access',
      redirect_uri: `${frontendUrl}/callback`,
    },
  });

  const isAuthenticated = () => {
    return auth.isAuthenticated();
  };
  const handleRedirectCallback = () => {
    return auth.handleRedirectCallback();
  };
  const getToken = () => {
    return auth.getTokenSilently();
  };
  const getUser = () => {
    return auth.getUser();
  };
  const login = async () => {
    await auth.loginWithRedirect();
  };
  const logout = async () => {
    await auth.logout({
      logoutParams: {
        returnTo: frontendUrl,
      },
    });
    window.localStorage.removeItem('show-information-search-modal');
  };

  return {
    provide: {
      auth: {
        isAuthenticated,
        handleRedirectCallback,
        login,
        logout,
        getUser,
        getToken,
      },
    },
  };
});
