import { defineStore } from 'pinia';

export const useProjectStore = defineStore('project', {

  state: () => ({
    project: {},
  }),

  actions: {
    async setProjectById (projectId) {
      const { $http, $i18n } = useNuxtApp();
      const { projects } = useEndpoints();
      const project = await $http.get(projects.getProject(projectId));
      this.project = project;
      const countryCode = project?.country_code?.toLowerCase() || (['méxico', 'mexico'].includes(project?.country?.toLowerCase()) ? 'mx' : 'co');
      $i18n.setLocale(countryCode);
    },
  },

  getters: {
    projectData (state) {
      return state?.project;
    },
  },

});
