export default defineNuxtRouteMiddleware(async () => {
  if (process.server) {
    return;
  }

  const { $auth } = useNuxtApp();
  const isAuthenticated = await $auth.isAuthenticated();
  const userStore = useUserStore();

  if (isAuthenticated && !userStore.userInfo.id) {
    await userStore.getUser();
  }

  if (!userStore.isAdmin) {
    return navigateTo('/projects');
  }
});
