<template>
  <RoomieOverlay>
    <section ref="target" class="action-menu">
      <NuxtLink to="/subscriptions" class="action-menu__item" data-seller="action-menu-go-to-notifications" @click="emit('close')">
        <RoomieIcon class="action-menu__icon" icon-name="notification" />
        {{ $t('actionMenu.notification') }}
      </NuxtLink>
      <NuxtLink to="/admin/users" data-seller="action-menu-go-to-users" class="action-menu__item" @click="emit('close')">
        <RoomieIcon class="action-menu__icon" icon-name="user" />
        {{ $t('actionMenu.users') }}
      </NuxtLink>
      <div data-seller="action-menu-logout" class="action-menu__item action-menu__item--logout" @click="$auth.logout">
        <RoomieIcon class="action-menu__icon" icon-name="close" />
        {{ $t('actionMenu.logout') }}
      </div>
    </section>
  </RoomieOverlay>
</template>

<script setup lang="js">
import RoomieOverlay from '@lahaus-roomie/roomie/src/components/RoomieOverlay/index.vue';
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue';

const emit = defineEmits(['close']);
const target = ref(null);
onClickOutside(target, (event) => {
  if (event.target.id === 'lh-seller-menu-options-btn') {
    return;
  }

  emit('close');
});
</script>

<style lang="scss" scoped>
.action-menu {
  @apply flex flex-col gap-lh-4;
  @apply w-lh-250 min-h-[16.8rem];
  @apply mr-lh-30 md:mr-lh-40 lg:mr-lh-50 xl:mr-lh-70 p-lh-8;
  @apply bg-white rounded-lh-8;

  box-shadow: 0 8px 16px 2px rgb(72 91 87 / 20%);

  &__item {
    @apply flex items-center gap-lh-8;
    @apply py-lh-12 px-lh-16;
    @apply rounded-lh-4 cursor-pointer;
    @apply hover:bg-carbon-light-50;
    @apply text-14 text-carbon-600 font-normal leading-24;

    &--logout {
      @apply justify-center;
      @apply bg-carbon-light-50;
    }
  }

  &__icon {
    @apply w-lh-24 h-lh-24;
    @apply text-carbon-600;
  }
}

.roomie-overlay {
  @apply mt-lh-56;
  @apply justify-end items-start;
}
</style>
