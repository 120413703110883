import { default as indexmZcn0ydRX9Meta } from "/app/pages/admin/users/[user]/edit/index.vue?macro=true";
import { default as indexYm9dzWmM2AMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as indexU4nDxO7mfOMeta } from "/app/pages/admin/users/new/index.vue?macro=true";
import { default as indexurUW9cMyGZMeta } from "/app/pages/backoffice/index.vue?macro=true";
import { default as indexcDEUwTAWYyMeta } from "/app/pages/backoffice/projects/[project]/edit/index.vue?macro=true";
import { default as index8Z28yTMrB0Meta } from "/app/pages/backoffice/projects/index.vue?macro=true";
import { default as indexveUuovhoMfMeta } from "/app/pages/backoffice/sync_up/index.vue?macro=true";
import { default as indexsTJSM0VXYwMeta } from "/app/pages/callback/index.vue?macro=true";
import { default as indexY6XMNc3MKlMeta } from "/app/pages/companies/[company]/index.vue?macro=true";
import { default as indexiMPp3LAMl3Meta } from "/app/pages/companies/[company]/users/index.vue?macro=true";
import { default as indexQlB152bJg8Meta } from "/app/pages/external/companies/[companyId]/users/index.vue?macro=true";
import { default as indexOx40S7HQLDMeta } from "/app/pages/external/companies/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexp9LRNTnNwLMeta } from "/app/pages/payment_plans/[payment_plan]/index.vue?macro=true";
import { default as indexk6yPMDfa0rMeta } from "/app/pages/payment_plans/index.vue?macro=true";
import { default as indexubS83KCBwCMeta } from "/app/pages/project_registration/[project]/inventory/index.vue?macro=true";
import { default as index9WtxIhVjBMMeta } from "/app/pages/project_registration/[project]/multimedia_content/index.vue?macro=true";
import { default as indexCZmlHWpjj4Meta } from "/app/pages/project_registration/[project]/payment_plans/index.vue?macro=true";
import { default as indexJZ0hQcBV6BMeta } from "/app/pages/project_registration/index.vue?macro=true";
import { default as indexfECqbwVOHcMeta } from "/app/pages/projects/[project]/amenities/index.vue?macro=true";
import { default as indexfI1fclgsFAMeta } from "/app/pages/projects/[project]/basic_information/index.vue?macro=true";
import { default as indexbUmsa3iSCHMeta } from "/app/pages/projects/[project]/bot/index.vue?macro=true";
import { default as indexdb2b6TSvFxMeta } from "/app/pages/projects/[project]/bot/knowledge_base/index.vue?macro=true";
import { default as indexTuNbkP7M89Meta } from "/app/pages/projects/[project]/bot/knowledge_base/question/[[question]]/index.vue?macro=true";
import { default as indexPIcb5pkqLDMeta } from "/app/pages/projects/[project]/bot/knowledge_base/search/index.vue?macro=true";
import { default as indexsyvJxmxvv6Meta } from "/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/complete_property/index.vue?macro=true";
import { default as indextwmSk1IAShMeta } from "/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/documents/index.vue?macro=true";
import { default as indexl1MxIb4YsKMeta } from "/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/fuv/index.vue?macro=true";
import { default as indexLVleBvm0UrMeta } from "/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/index.vue?macro=true";
import { default as indexmf9bQqFvCKMeta } from "/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/payment_plans/[payment_plan]/index.vue?macro=true";
import { default as indexGKkiE4PSBWMeta } from "/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/payment_plans/index.vue?macro=true";
import { default as index0NwXZjyZwEMeta } from "/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/payments/index.vue?macro=true";
import { default as indexTG9b3XmcKDMeta } from "/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/separation-manager/index.vue?macro=true";
import { default as indexltIakoU5JiMeta } from "/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/v1/index.vue?macro=true";
import { default as indexK5tZy5T7yLMeta } from "/app/pages/projects/[project]/business/index.vue?macro=true";
import { default as indexLtVNSw44zCMeta } from "/app/pages/projects/[project]/business/payment_plans/[payment_plan]/index.vue?macro=true";
import { default as indexbwgQ0Xa9IJMeta } from "/app/pages/projects/[project]/business/payment_plans/index.vue?macro=true";
import { default as fuvZSN3NIXGzLMeta } from "/app/pages/projects/[project]/business/separations/[separation]/customers/[customer]/fuv.vue?macro=true";
import { default as index3mNofvcednMeta } from "/app/pages/projects/[project]/cms/index.vue?macro=true";
import { default as indexyoinzrVASlMeta } from "/app/pages/projects/[project]/dashboard/early_access/index.vue?macro=true";
import { default as indexNSYCN5qrGfMeta } from "/app/pages/projects/[project]/dashboard/index.vue?macro=true";
import { default as indexE3iRCCjJ3UMeta } from "/app/pages/projects/[project]/dashboard/summary/index.vue?macro=true";
import { default as indexIKANqW3gGAMeta } from "/app/pages/projects/[project]/dashboard/transactions/index.vue?macro=true";
import { default as indexVZEROMrFGCMeta } from "/app/pages/projects/[project]/data_sheet/index.vue?macro=true";
import { default as index502YQUMjcBMeta } from "/app/pages/projects/[project]/escalations/price_escalation/index.vue?macro=true";
import { default as indexLFfzbWLuHKMeta } from "/app/pages/projects/[project]/escalations/take_rate_escalation/index.vue?macro=true";
import { default as indexGFTRC83490Meta } from "/app/pages/projects/[project]/financial_plans/[plan]/index.vue?macro=true";
import { default as indexcNBJyW83OZMeta } from "/app/pages/projects/[project]/financial_plans/create/index.vue?macro=true";
import { default as indexaqtu3wEtObMeta } from "/app/pages/projects/[project]/financial_plans/index.vue?macro=true";
import { default as indexFPnVbaEYxeMeta } from "/app/pages/projects/[project]/form_inventory/index.vue?macro=true";
import { default as indexOFeywCJRFjMeta } from "/app/pages/projects/[project]/inventory/index.vue?macro=true";
import { default as index6QmkuR6rdpMeta } from "/app/pages/projects/[project]/list_inventory/index.vue?macro=true";
import { default as indexlntnJzXlMUMeta } from "/app/pages/projects/[project]/multimedia_content/brochure/index.vue?macro=true";
import { default as indexTBwgHlbLZpMeta } from "/app/pages/projects/[project]/multimedia_content/galleries/index.vue?macro=true";
import { default as indexG1yR08PEd3Meta } from "/app/pages/projects/[project]/multimedia_content/links/index.vue?macro=true";
import { default as indexnq1zDV5WISMeta } from "/app/pages/projects/[project]/payments/index.vue?macro=true";
import { default as constantsu0HCkjsxuiMeta } from "/app/pages/projects/[project]/properties/[propertyId]/quotator/constants.js?macro=true";
import { default as indexqH4q4VqozwMeta } from "/app/pages/projects/[project]/properties/[propertyId]/quotator/index.vue?macro=true";
import { default as indexScOVFFJJBXMeta } from "/app/pages/projects/[project]/required_documents/index.vue?macro=true";
import { default as indexBBcyfp7wBPMeta } from "/app/pages/projects/[project]/separations/index.vue?macro=true";
import { default as indexYZH1JSJ2QrMeta } from "/app/pages/projects/[project]/setting/index.vue?macro=true";
import { default as indexEhJoGYcW5XMeta } from "/app/pages/projects/[project]/whatsapp_group/index.vue?macro=true";
import { default as indexdd0y1GP2UcMeta } from "/app/pages/projects/index.vue?macro=true";
import { default as editaKO64ODBP6Meta } from "/app/pages/projects/knowledge-base/[uuid]/edit.vue?macro=true";
import { default as indexFp7yrswLRWMeta } from "/app/pages/projects/knowledge-base/index.vue?macro=true";
import { default as new0TWEjgYy2dMeta } from "/app/pages/projects/knowledge-base/new.vue?macro=true";
import { default as indexiuF5jYzH0yMeta } from "/app/pages/redirect_login/index.vue?macro=true";
import { default as indexLtF3BD24HtMeta } from "/app/pages/s/[id]/index.vue?macro=true";
import { default as indexrOL1EKYmM2Meta } from "/app/pages/subscriptions/index.vue?macro=true";
import { default as indexCzeaSHQHOeMeta } from "/app/pages/terms_and_conditions/index.vue?macro=true";
export default [
  {
    name: "admin-users-user-edit",
    path: "/admin/users/:user()/edit",
    meta: indexmZcn0ydRX9Meta || {},
    component: () => import("/app/pages/admin/users/[user]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexYm9dzWmM2AMeta || {},
    component: () => import("/app/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-new",
    path: "/admin/users/new",
    meta: indexU4nDxO7mfOMeta || {},
    component: () => import("/app/pages/admin/users/new/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice",
    path: "/backoffice",
    meta: indexurUW9cMyGZMeta || {},
    component: () => import("/app/pages/backoffice/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-projects-project-edit",
    path: "/backoffice/projects/:project()/edit",
    meta: indexcDEUwTAWYyMeta || {},
    component: () => import("/app/pages/backoffice/projects/[project]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-projects",
    path: "/backoffice/projects",
    meta: index8Z28yTMrB0Meta || {},
    component: () => import("/app/pages/backoffice/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-sync_up",
    path: "/backoffice/sync_up",
    meta: indexveUuovhoMfMeta || {},
    component: () => import("/app/pages/backoffice/sync_up/index.vue").then(m => m.default || m)
  },
  {
    name: "callback",
    path: "/callback",
    meta: indexsTJSM0VXYwMeta || {},
    component: () => import("/app/pages/callback/index.vue").then(m => m.default || m)
  },
  {
    name: "companies-company",
    path: "/companies/:company()",
    component: () => import("/app/pages/companies/[company]/index.vue").then(m => m.default || m)
  },
  {
    name: "companies-company-users",
    path: "/companies/:company()/users",
    component: () => import("/app/pages/companies/[company]/users/index.vue").then(m => m.default || m)
  },
  {
    name: "external-companies-companyId-users",
    path: "/external/companies/:companyId()/users",
    meta: indexQlB152bJg8Meta || {},
    component: () => import("/app/pages/external/companies/[companyId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: "external-companies",
    path: "/external/companies",
    meta: indexOx40S7HQLDMeta || {},
    component: () => import("/app/pages/external/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "payment_plans-payment_plan",
    path: "/payment_plans/:payment_plan()",
    meta: indexp9LRNTnNwLMeta || {},
    component: () => import("/app/pages/payment_plans/[payment_plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "payment_plans",
    path: "/payment_plans",
    meta: indexk6yPMDfa0rMeta || {},
    component: () => import("/app/pages/payment_plans/index.vue").then(m => m.default || m)
  },
  {
    name: "project_registration-project-inventory",
    path: "/project_registration/:project()/inventory",
    meta: indexubS83KCBwCMeta || {},
    component: () => import("/app/pages/project_registration/[project]/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: "project_registration-project-multimedia_content",
    path: "/project_registration/:project()/multimedia_content",
    meta: index9WtxIhVjBMMeta || {},
    component: () => import("/app/pages/project_registration/[project]/multimedia_content/index.vue").then(m => m.default || m)
  },
  {
    name: "project_registration-project-payment_plans",
    path: "/project_registration/:project()/payment_plans",
    meta: indexCZmlHWpjj4Meta || {},
    component: () => import("/app/pages/project_registration/[project]/payment_plans/index.vue").then(m => m.default || m)
  },
  {
    name: "project_registration",
    path: "/project_registration",
    meta: indexJZ0hQcBV6BMeta || {},
    component: () => import("/app/pages/project_registration/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-amenities",
    path: "/projects/:project()/amenities",
    meta: indexfECqbwVOHcMeta || {},
    component: () => import("/app/pages/projects/[project]/amenities/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-basic_information",
    path: "/projects/:project()/basic_information",
    meta: indexfI1fclgsFAMeta || {},
    component: () => import("/app/pages/projects/[project]/basic_information/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-bot",
    path: "/projects/:project()/bot",
    meta: indexbUmsa3iSCHMeta || {},
    component: () => import("/app/pages/projects/[project]/bot/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-bot-knowledge_base",
    path: "/projects/:project()/bot/knowledge_base",
    meta: indexdb2b6TSvFxMeta || {},
    component: () => import("/app/pages/projects/[project]/bot/knowledge_base/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-bot-knowledge_base-question-question",
    path: "/projects/:project()/bot/knowledge_base/question/:question?",
    meta: indexTuNbkP7M89Meta || {},
    component: () => import("/app/pages/projects/[project]/bot/knowledge_base/question/[[question]]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-bot-knowledge_base-search",
    path: "/projects/:project()/bot/knowledge_base/search",
    meta: indexPIcb5pkqLDMeta || {},
    component: () => import("/app/pages/projects/[project]/bot/knowledge_base/search/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-customer_folder-cdp-unit-unit-opportunity-opportunity-complete_property",
    path: "/projects/:project()/business/customer_folder/:cdp()/unit/:unit()/opportunity/:opportunity()/complete_property",
    meta: indexsyvJxmxvv6Meta || {},
    component: () => import("/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/complete_property/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-customer_folder-cdp-unit-unit-opportunity-opportunity-documents",
    path: "/projects/:project()/business/customer_folder/:cdp()/unit/:unit()/opportunity/:opportunity()/documents",
    meta: indextwmSk1IAShMeta || {},
    component: () => import("/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-customer_folder-cdp-unit-unit-opportunity-opportunity-fuv",
    path: "/projects/:project()/business/customer_folder/:cdp()/unit/:unit()/opportunity/:opportunity()/fuv",
    meta: indexl1MxIb4YsKMeta || {},
    component: () => import("/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/fuv/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-customer_folder-cdp-unit-unit-opportunity-opportunity",
    path: "/projects/:project()/business/customer_folder/:cdp()/unit/:unit()/opportunity/:opportunity()",
    meta: indexLVleBvm0UrMeta || {},
    component: () => import("/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-customer_folder-cdp-unit-unit-opportunity-opportunity-payment_plans-payment_plan",
    path: "/projects/:project()/business/customer_folder/:cdp()/unit/:unit()/opportunity/:opportunity()/payment_plans/:payment_plan()",
    meta: indexmf9bQqFvCKMeta || {},
    component: () => import("/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/payment_plans/[payment_plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-customer_folder-cdp-unit-unit-opportunity-opportunity-payment_plans",
    path: "/projects/:project()/business/customer_folder/:cdp()/unit/:unit()/opportunity/:opportunity()/payment_plans",
    meta: indexGKkiE4PSBWMeta || {},
    component: () => import("/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/payment_plans/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-customer_folder-cdp-unit-unit-opportunity-opportunity-payments",
    path: "/projects/:project()/business/customer_folder/:cdp()/unit/:unit()/opportunity/:opportunity()/payments",
    meta: index0NwXZjyZwEMeta || {},
    component: () => import("/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-customer_folder-cdp-unit-unit-opportunity-opportunity-separation-manager",
    path: "/projects/:project()/business/customer_folder/:cdp()/unit/:unit()/opportunity/:opportunity()/separation-manager",
    meta: indexTG9b3XmcKDMeta || {},
    component: () => import("/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/separation-manager/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-customer_folder-cdp-unit-unit-opportunity-opportunity-v1",
    path: "/projects/:project()/business/customer_folder/:cdp()/unit/:unit()/opportunity/:opportunity()/v1",
    meta: indexltIakoU5JiMeta || {},
    component: () => import("/app/pages/projects/[project]/business/customer_folder/[cdp]/unit/[unit]/opportunity/[opportunity]/v1/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business",
    path: "/projects/:project()/business",
    meta: indexK5tZy5T7yLMeta || {},
    component: () => import("/app/pages/projects/[project]/business/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-payment_plans-payment_plan",
    path: "/projects/:project()/business/payment_plans/:payment_plan()",
    meta: indexLtVNSw44zCMeta || {},
    component: () => import("/app/pages/projects/[project]/business/payment_plans/[payment_plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-payment_plans",
    path: "/projects/:project()/business/payment_plans",
    meta: indexbwgQ0Xa9IJMeta || {},
    component: () => import("/app/pages/projects/[project]/business/payment_plans/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-business-separations-separation-customers-customer-fuv",
    path: "/projects/:project()/business/separations/:separation()/customers/:customer()/fuv",
    meta: fuvZSN3NIXGzLMeta || {},
    component: () => import("/app/pages/projects/[project]/business/separations/[separation]/customers/[customer]/fuv.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-cms",
    path: "/projects/:project()/cms",
    meta: index3mNofvcednMeta || {},
    component: () => import("/app/pages/projects/[project]/cms/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-dashboard-early_access",
    path: "/projects/:project()/dashboard/early_access",
    meta: indexyoinzrVASlMeta || {},
    component: () => import("/app/pages/projects/[project]/dashboard/early_access/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-dashboard",
    path: "/projects/:project()/dashboard",
    component: () => import("/app/pages/projects/[project]/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-dashboard-summary",
    path: "/projects/:project()/dashboard/summary",
    meta: indexE3iRCCjJ3UMeta || {},
    component: () => import("/app/pages/projects/[project]/dashboard/summary/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-dashboard-transactions",
    path: "/projects/:project()/dashboard/transactions",
    meta: indexIKANqW3gGAMeta || {},
    component: () => import("/app/pages/projects/[project]/dashboard/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-data_sheet",
    path: "/projects/:project()/data_sheet",
    meta: indexVZEROMrFGCMeta || {},
    component: () => import("/app/pages/projects/[project]/data_sheet/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-escalations-price_escalation",
    path: "/projects/:project()/escalations/price_escalation",
    meta: index502YQUMjcBMeta || {},
    component: () => import("/app/pages/projects/[project]/escalations/price_escalation/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-escalations-take_rate_escalation",
    path: "/projects/:project()/escalations/take_rate_escalation",
    meta: indexLFfzbWLuHKMeta || {},
    component: () => import("/app/pages/projects/[project]/escalations/take_rate_escalation/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-financial_plans-plan",
    path: "/projects/:project()/financial_plans/:plan()",
    meta: indexGFTRC83490Meta || {},
    component: () => import("/app/pages/projects/[project]/financial_plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-financial_plans-create",
    path: "/projects/:project()/financial_plans/create",
    meta: indexcNBJyW83OZMeta || {},
    component: () => import("/app/pages/projects/[project]/financial_plans/create/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-financial_plans",
    path: "/projects/:project()/financial_plans",
    meta: indexaqtu3wEtObMeta || {},
    component: () => import("/app/pages/projects/[project]/financial_plans/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-form_inventory",
    path: "/projects/:project()/form_inventory",
    component: () => import("/app/pages/projects/[project]/form_inventory/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-inventory",
    path: "/projects/:project()/inventory",
    meta: indexOFeywCJRFjMeta || {},
    component: () => import("/app/pages/projects/[project]/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-list_inventory",
    path: "/projects/:project()/list_inventory",
    meta: index6QmkuR6rdpMeta || {},
    component: () => import("/app/pages/projects/[project]/list_inventory/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-multimedia_content-brochure",
    path: "/projects/:project()/multimedia_content/brochure",
    meta: indexlntnJzXlMUMeta || {},
    component: () => import("/app/pages/projects/[project]/multimedia_content/brochure/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-multimedia_content-galleries",
    path: "/projects/:project()/multimedia_content/galleries",
    meta: indexTBwgHlbLZpMeta || {},
    component: () => import("/app/pages/projects/[project]/multimedia_content/galleries/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-multimedia_content-links",
    path: "/projects/:project()/multimedia_content/links",
    meta: indexG1yR08PEd3Meta || {},
    component: () => import("/app/pages/projects/[project]/multimedia_content/links/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-payments",
    path: "/projects/:project()/payments",
    component: () => import("/app/pages/projects/[project]/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-properties-propertyId-quotator-constants",
    path: "/projects/:project()/properties/:propertyId()/quotator/constants",
    component: () => import("/app/pages/projects/[project]/properties/[propertyId]/quotator/constants.js").then(m => m.default || m)
  },
  {
    name: "projects-project-properties-propertyId-quotator",
    path: "/projects/:project()/properties/:propertyId()/quotator",
    meta: indexqH4q4VqozwMeta || {},
    component: () => import("/app/pages/projects/[project]/properties/[propertyId]/quotator/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-required_documents",
    path: "/projects/:project()/required_documents",
    meta: indexScOVFFJJBXMeta || {},
    component: () => import("/app/pages/projects/[project]/required_documents/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-separations",
    path: "/projects/:project()/separations",
    meta: indexBBcyfp7wBPMeta || {},
    component: () => import("/app/pages/projects/[project]/separations/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-setting",
    path: "/projects/:project()/setting",
    meta: indexYZH1JSJ2QrMeta || {},
    component: () => import("/app/pages/projects/[project]/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-whatsapp_group",
    path: "/projects/:project()/whatsapp_group",
    meta: indexEhJoGYcW5XMeta || {},
    component: () => import("/app/pages/projects/[project]/whatsapp_group/index.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexdd0y1GP2UcMeta || {},
    component: () => import("/app/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-knowledge-base-uuid-edit",
    path: "/projects/knowledge-base/:uuid()/edit",
    meta: editaKO64ODBP6Meta || {},
    component: () => import("/app/pages/projects/knowledge-base/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "projects-knowledge-base",
    path: "/projects/knowledge-base",
    meta: indexFp7yrswLRWMeta || {},
    component: () => import("/app/pages/projects/knowledge-base/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-knowledge-base-new",
    path: "/projects/knowledge-base/new",
    meta: new0TWEjgYy2dMeta || {},
    component: () => import("/app/pages/projects/knowledge-base/new.vue").then(m => m.default || m)
  },
  {
    name: "redirect_login",
    path: "/redirect_login",
    meta: indexiuF5jYzH0yMeta || {},
    component: () => import("/app/pages/redirect_login/index.vue").then(m => m.default || m)
  },
  {
    name: "s-id",
    path: "/s/:id()",
    component: () => import("/app/pages/s/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    meta: indexrOL1EKYmM2Meta || {},
    component: () => import("/app/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms_and_conditions",
    path: "/terms_and_conditions",
    meta: indexCzeaSHQHOeMeta || {},
    component: () => import("/app/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  }
]