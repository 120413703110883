<template>
  <section
    v-if="showTwilioFlex"
    :class="{ 'twilio-flex': true, 'twilio-flex__container': shouldShowIframe }"
  >
    <section v-if="!shouldShowIframe">
      <span class="twilio-flex__company-service-label">{{ $t('index.chatWithCompanies') }}</span>
      <div class="grid justify-center">
        <button
          data-seller="company-service-whatsapp-btn"
          class="twilio-flex__company-service-whatsapp-btn"
          @click="startTwilioFlexChat"
        >
          <RoomieIcon
            icon-name="whatsapp-filled"
            class="twilio-flex__icon"
          />
        </button>
      </div>
    </section>
    <section
      v-show="shouldShowIframe"
      class="twilio-flex__iframe"
    >
      <div
        class="twilio-flex__iframe__hide-button"
        @click="shouldShowIframe = false"
      >
        - {{ $t('index.hideChatWithCompanies') }}
      </div>
      <iframe
        src="https://flex.twilio.com"
        class="w-full h-full"
      />
    </section>
  </section>
</template>
<script setup>
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue';

const route = useRoute();
const { $http } = useNuxtApp();
const { showButtonTwilioFlex, emailsToShowButtonTwilioFlex } = useRuntimeConfig().public;
const projectStore = useProjectStore();
const userStore = useUserStore();

const shouldShowIframe = ref(false);

const showTwilioFlex = computed(() => {
  const emails = (emailsToShowButtonTwilioFlex || '').split(',');
  return showButtonTwilioFlex !== '' && emails.includes(userStore.userInfo.email);
});

const startTwilioFlexChat = async () => {
  shouldShowIframe.value = true;
  if (route.params?.project && projectStore.projectData.id !== route.params?.project) {
    await projectStore.setProjectById(route.params?.project);
    await startMessageWithCompany();
  }
};

const startMessageWithCompany = async () => {
  if ((projectStore.projectData?.contact_info || []).length > 0) {
    const contact = projectStore.projectData?.contact_info[0];
    const name = `${ projectStore.projectData?.name } - ${ contact?.name }`;
    let phone = contact?.phone || '';
    if (!phone.includes('+')) {
      phone = projectStore.projectData.country === 'Colombia' ? `+57${phone}` : `+52${phone}`;
    }

    if (userStore.userInfo?.extraInfo?.twilio_flex?.worker_email !== '') {
      await $http.post('/api/comms/start_message', {
        name,
        phone,
        worker_email: userStore.userInfo?.extraInfo?.twilio_flex?.worker_email,
        worker_name: userStore.userInfo?.extraInfo?.twilio_flex?.worker_name,
      });
    }
  }
};
</script>

<style scoped>
.twilio-flex {
  @apply fixed right-[0.5rem] bottom-[4rem] xl:right-[1rem] z-[200];

  &__container {
  @apply w-full h-full md:w-6/12 md:h-3/4;
  }

  &__company-service {
    @apply fixed right-[0.5rem] bottom-[10rem] xl:right-[1rem] z-[200];
    @apply grid justify-items-center gap-5;

    &-whatsapp-btn {
      @apply cursor-pointer rounded-full;
      @apply bg-[#147f67] hover:bg-primary-800 text-white;
      @apply w-lh-50 h-lh-50 md:w-[6.5rem] md:h-[6.5rem];
      @apply flex items-center justify-center;
    }

    &-label {
      @apply text-carbon-600 font-medium bg-white;
      @apply hidden xl:block;
    }
  }

  &__icon {
    @apply w-lh-30 h-lh-30 md:w-[4.5rem] md:h-[4.5rem];
  }

  &__iframe {
    @apply w-full h-full relative;

    &__hide-button {
      @apply cursor-pointer bg-[#f5f7f6] rounded-lg p-2 sticky top-0;
    }
  }
}
</style>
