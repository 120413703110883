import esCo from './es-CO';
import esMx from './es-MX';

const messages = {
  co: esCo,
  mx: esMx,
};

export default {
  ...messages,
};
