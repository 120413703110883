export default defineNuxtPlugin(() => {
  const removeRestricts = () => {
    const restrictElts = document.querySelectorAll('[data-restrict]');

    if (restrictElts.length === 0) {
      return;
    }

    restrictElts.forEach((elt) => {
      if (!hasPermissions(elt.getAttribute('data-restrict'))) {
        elt.remove();
      }
    });
  };

  const observer = new MutationObserver(removeRestricts);
  observer.observe(document.body, {
    childList: true,
    subtree: true,
    attributeFilter: ['data-restrict'],
  });

  function hasPermissions (restrict) {
    const userStore = useUserStore();
    return !userStore.userRestricts.includes(restrict);
  }

  return {
    provide: {
      hasPermissions,
    },
  };
});
