<template>
  <footer class="base-footer">
    <div class="base-footer__terms">
      <p class="base-footer__terms-copyright">
        © {{ getCurrentYear() }} {{ copyrightText }}
      </p>
      <ul class="base-footer__bottom-links">
        <li v-for="item in footerTerms" :key="item.id">
          <a
            class="base-footer__bottom-link"
            :href="item.link"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ item.description }}
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script setup lang="js">
import { useI18n } from 'vue-i18n';

defineProps({
  copyrightText: {
    type: String,
    required: false,
    default: 'La Haus | v12.34.93',
  },
});

const { t, locale } = useI18n();
const footerTerms = computed(() => {
  return {
    co: [
      { id: 0, description: t('footer.termsAndConditions'), link: '/terms_and_conditions' },
      { id: 1, description: t('footer.cookies'), link: 'https://www.lahaus.com/static/politica-cookies-co-v1' },
      { id: 2, description: t('footer.data'), link: 'https://www.lahaus.com/politicas-tratamiento-datos' },
      { id: 3, description: t('footer.systemStatus'), link: 'https://ims-lahaus.statuspage.io/' },
    ],
    mx: [
      { id: 0, description: t('footer.termsAndConditions'), link: '/terms_and_conditions' },
      { id: 1, description: t('footer.cookies'), link: 'https://www.lahaus.mx/static/politica-cookies-mx-v1' },
      { id: 2, description: t('footer.data'), link: 'https://www.lahaus.mx/politica-y-aviso-de-privacidad' },
      { id: 3, description: t('footer.systemStatus'), link: 'https://ims-lahaus.statuspage.io/' },
    ],
  }[locale.value || 'co'];
});
const getCurrentYear = () => {
  const today = new Date();
  return today.getFullYear();
};
</script>

<style lang="scss" scoped>
$sm: 640px;

%caption-1 {
  @apply text-12 leading-16 tracking-rm-1 text-white font-regular;
}

%content {
  @apply mr-lh-10;
  @apply text-10 text-white;
}

.base-footer {
  @apply flex flex-row justify-between items-center;
  @apply w-full p-lh-24;
  @apply bg-primary-800;

  &__terms {
    &-copyright {
      @extend %caption-1;
    }
  }

  &__bottom {
    &-links {
      @apply flex flex-wrap;

      li {
        @apply mr-lh-10;

        &::before {
          content: "•";

          @extend %content;
        }

        &:first-child,
        &:last-child {
          &::before {
            content: none;
          }
        }

        @media (min-width: $sm) {
          &:last-child {
            &::before {
              content: "•";

              @apply mr-lh-10;
              @apply text-10 text-white;
            }
          }
        }
      }
    }

    &-link {
      @extend %caption-1;
      @apply underline;
    }
  }
}
</style>
