import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import accounting_kylXAoGLN0 from "/app/plugins/accounting.js";
import auth_client_E5M9zMdRCQ from "/app/plugins/auth.client.js";
import axios_sVCuMR6hEC from "/app/plugins/axios.js";
import bus_GHaWeQPCWC from "/app/plugins/bus.js";
import dataLayer_TS827bf7Pz from "/app/plugins/dataLayer.js";
import datadog_client_4VQQkl3wSE from "/app/plugins/datadog.client.js";
import dateFns_lfosnPcMdm from "/app/plugins/dateFns.js";
import gtag_client_R70iZRv6jK from "/app/plugins/gtag.client.js";
import hideRestricts_1GR2lJy6r3 from "/app/plugins/hideRestricts.js";
import locations_PjbgKJW63B from "/app/plugins/locations.js";
import rollbar_NnZoFdA7so from "/app/plugins/rollbar.js";
import vite_events_01I6Vj6lmW from "/app/plugins/vite.events.js";
import vue_google_maps_MYJNQAjJTq from "/app/plugins/vue.google.maps.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  accounting_kylXAoGLN0,
  auth_client_E5M9zMdRCQ,
  axios_sVCuMR6hEC,
  bus_GHaWeQPCWC,
  dataLayer_TS827bf7Pz,
  datadog_client_4VQQkl3wSE,
  dateFns_lfosnPcMdm,
  gtag_client_R70iZRv6jK,
  hideRestricts_1GR2lJy6r3,
  locations_PjbgKJW63B,
  rollbar_NnZoFdA7so,
  vite_events_01I6Vj6lmW,
  vue_google_maps_MYJNQAjJTq
]