export default defineNuxtPlugin((nuxtApp) => {
  const handleClick = (binding) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'cta-interaction',
      ...binding,
    });
  };

  nuxtApp.vueApp.directive('data-layer', {
    mounted (el, binding) {
      el._handleClick = () => handleClick(binding.value);
      el.addEventListener('click', el._handleClick);
    },
    beforeUnmount (el) {
      el.removeEventListener('click', el._handleClick);
    },
  });
});
