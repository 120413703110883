export default defineNuxtPlugin(() => {
  const getPlaces = async (lat, lng) => {
    const { $http } = useNuxtApp();
    const response = await $http.get(`/api/locations/search?lat=${lat}&lng=${lng}`);
    return formatFromLocations(response);
  };

  const formatFromLocations = (response) => {
    if (response?.status !== 'OK') {
      return null;
    }

    const data = {};
    data.country = response?.result?.country?.alias;
    data.state = response?.result?.state?.alias;
    data.city = response?.result?.city?.alias;
    data.neighborhood = response?.result?.neighborhood?.alias || response?.result?.unit?.alias;
    data.zone = response?.result?.zone?.alias;
    data.country_code = response?.result?.country?.code || '';
    data.state_code = response?.result?.state?.code || '';
    data.city_code = response?.result?.city?.code || '';
    data.zone_code = response?.result?.zone?.code || '';
    data.neighborhood_code = response?.result?.neighborhood?.code || '';
    data.business_hub_name = response?.result?.hub?.name || '';
    data.business_hub_code = response?.result?.hub?.code || '';

    return data;
  };

  const formatFromGoogle = (place) => {
    const addressParts = place.address_components;
    const data = {};
    data.country = getAddressPart(addressParts, 'country')?.long_name || '';
    data.state = getAddressPart(addressParts, 'administrative_area_level_1')?.short_name || '';
    data.city = getAddressPart(addressParts, 'locality')?.long_name || '';
    data.zone = getAddressPart(addressParts, 'sublocality')?.long_name || '';
    data.postalCode = getAddressPart(addressParts, 'postal_code')?.short_name || '';
    data.neighborhood = getAddressPart(addressParts, 'neighborhood')?.long_name || place.vicinity || '';
    data.address = place.formatted_address.split(',')[0];
    data.country_code = '';
    data.state_code = '';
    data.city_code = '';
    data.zone_code = '';
    data.neighborhood_code = '';
    data.business_hub_name = '';
    data.business_hub_code = '';
    return data;
  };

  const getAddressPart = (parts, type) => {
    return parts.find(part => part.types.includes(type));
  };

  return {
    provide: {
      locations: {
        getPlaces,
        formatFromGoogle,
      },
    },
  };
});
