import { defineStore } from 'pinia';

export const useToastStore = defineStore('toast', () => {
  const toasts = ref([]);
  const getToasts = computed(() => toasts.value);

  function show ({ id, title, description, variant = 'success', ...config }) {
    toasts.value.push({
      id,
      title,
      description,
      variant,
      timeToHide: 8000,
      ...config,
    });
  }

  function onClosed (toastId) {
    const toastIdx = toasts.value.findIndex(({ id }) => id === toastId);

    if (toastIdx === -1) {
      return;
    }

    toasts.value.splice(toastIdx, 1);
  }

  return {
    getToasts,
    show,
    onClosed,
  };
});
