export default function () {
  const loggerCallback = (level, { message: msg, ...payload }) => {
    const out = {
      level,
      msg,
      time: new Date().toISOString(),
      service: process.env.npm_package_name,
      appVersion: process.env.npm_package_version,
      eventType: 'frontend',
      ...payload,
    };

    if (process.server) {
      process.stdout.write(`${JSON.stringify(out)}\n`);
      return;
    }

    useFetch('/api/logger', {
      method: 'POST',
      body: {
        out,
      },
    });
  };

  const logger = {
    debug: (message, payload) => loggerCallback('debug', { message, ...payload }),
    info: (message, payload) => loggerCallback('info', { message, ...payload }),
    warning: (message, payload) => loggerCallback('warning', { message, ...payload }),
    error: (message, payload) => loggerCallback('error', { message, ...payload }),
    emergency: (message, payload) => loggerCallback('emergency', { message, ...payload }),
  };

  return logger;
}
