import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const requestInterceptor = async (config) => {
  const { $auth } = useNuxtApp();
  const { xApplicationId } = useRuntimeConfig().public;
  const accessToken = await $auth.getToken().catch(() => $auth.logout());
  const commons = config.headers;
  const requestId = uuidv4();
  const { customHeaders = {} } = config;

  config.headers = {
    ...commons,
    'X-Application-Id': xApplicationId,
    'X-Request-Id': requestId,
    'X-Amzn-Trace-Id': `Request-ID=${requestId}`,
    ...customHeaders,
  };

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
};

export const responseInterceptor = (response = []) => {
  // Do something with response data
  return response.data;
};

export const errorInterceptor = (error) => {
  const { response = {}, request = {} } = error;
  const { status, statusText, data = {}, headers } = response;
  const { __rollbar_xhr: xhrInfo = {} } = request;
  const { error: responseError, errors = [] } = data;
  const logger = useLogger();
  const toastStore = useToastStore();
  const { $i18n } = useNuxtApp();
  const errorToShow = responseError || errors?.toString();

  let errorMessage = $i18n.t(`errors.${statusText || responseError}`);
  if (errors.length) {
    const arrayErrors = errors?.join(', ');
    errorMessage = `${errorMessage}: ${arrayErrors}`;
  }

  logger.error(statusText || errorToShow, {
    attributes: {
      url: xhrInfo.url,
      httpMethod: xhrInfo.method,
      duration: (xhrInfo.end_time_ms - xhrInfo.start_time_ms) / 1000,
      statusCode: status,
      headers,
    },
  });

  toastStore.show({
    id: uuidv4(),
    title: $i18n.t('errors.title'),
    description: errorMessage,
    variant: 'error',
  });

  return { ok: false, response };
};

export default defineNuxtPlugin(() => {
  const http = axios.create({});

  http.defaults.validateStatus = status => status === 401 || (status >= 200 && status < 400);
  http.interceptors.request.use(requestInterceptor);
  http.interceptors.response.use(responseInterceptor, errorInterceptor);

  return {
    provide: {
      http,
    },
  };
});
