import { defineStore } from 'pinia';

export const useHistoryStore = defineStore('history', () => {
  const backPage = ref([]);
  const getBackPage = computed(() => backPage);

  function setBackPage (route) {
    backPage.value = route;
  }

  return {
    setBackPage,
    getBackPage,
  };
});
