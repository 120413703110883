import { formatNumber, unformat, formatMoney } from 'accounting-js';

export default defineNuxtPlugin(() => {
  const formatValue = (value, options = { precision: 0 }) =>
    formatNumber(value, options);
  const unformatValue = (value, options) => unformat(value, options);

  return {
    provide: {
      formatValue,
      unformatValue,
      formatMoney: (value, options = { precision: 0 }) => formatMoney(value, options),
    },
  };
});
