<template>
  <main class="layout">
    <RoomieHeader v-if="showHeader">
      <template v-if="userInfo.id && getBackPage" #leftAction>
        <NuxtLink :to="getBackPage">
          <RoomieNavigationButton
            id="lh-seller-menu-go-back-btn"
            data-seller="lh-seller-menu-go-back-btn"
            :variant="BUTTON_VARIANT.light"
            :size="BUTTON_SIZE.sm"
          >
            <RoomieIcon icon-name="left" />
          </RoomieNavigationButton>
        </NuxtLink>
      </template>
      <template #rightAction>
        <RoomieNavigationButton
          v-if="userInfo.id"
          id="lh-seller-menu-options-btn"
          data-seller="lh-seller-menu-options-btn"
          :variant="BUTTON_VARIANT.ghost"
          :size="BUTTON_SIZE.lg"
          @click="showMenu = !showMenu"
        >
          <MenuIcon v-if="!showMenu" />
          <CloseIcon v-else />
        </RoomieNavigationButton>
      </template>
    </RoomieHeader>
    <section class="layout__container">
      <ActionMenu
        v-if="showMenu"
        @close="showMenu = false"
      />
      <div class="layout__toast-container">
        <RoomieToast
          v-for="toast in toastStore.getToasts"
          :id="toast.id"
          :key="toast.id"
          is-visible
          :title="toast.title"
          :description="toast.description"
          :variant="toast.variant"
          :time-to-hide="toast.timeToHide"
          @toast-closed="toastStore.onClosed(toast.id)"
        />
      </div>
      <section class="layout__slot-container">
        <section
          v-if="showCustomerServiceAssistance"
          class="layout__customer-service"
        >
          <span class="layout__customer-service-label">{{ $t('index.needHelp') }}</span>
          <button
            data-seller="customer-service-whatsapp-btn"
            class="layout__customer-service-whatsapp-btn"
            @click="navigateTo(customerServiceWhatsapp, { open: { target: '_blank' } })"
          >
            <RoomieIcon
              icon-name="whatsapp-filled"
              class="layout__icon"
            />
          </button>
        </section>
        <TwilioFlex />
        <slot />
      </section>
      <BaseFooter v-if="showFooter" />
    </section>
  </main>
</template>

<script setup lang="js">
import RoomieHeader from '@lahaus-roomie/roomie/src/components/RoomieHeader/index.vue';
import RoomieNavigationButton, { VARIANT, SIZE } from '@lahaus-roomie/roomie/src/components/RoomieNavigationButton/index.vue';
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue';
import RoomieToast from '@lahaus-roomie/roomie/src/components/RoomieToast/index.vue';
import MenuIcon from '@lahaus-roomie/roomie/src/assets/icons/menu.svg';
import CloseIcon from '@lahaus-roomie/roomie/src/assets/icons/close.svg';
import BaseFooter from '@/components/base/footer/index.vue';
import ActionMenu from '@/components/actionMenu/index.vue';
import TwilioFlex from '@/components/twilioFlex/index.vue';

const { customerServiceWhatsapp, allyRoleId, paymentPlanReviewerRoleId } = useRuntimeConfig().public;
const { userInfo } = useUserStore();

const BUTTON_VARIANT = VARIANT;
const BUTTON_SIZE = SIZE;

const showMenu = ref(false);
const route = useRoute();
const toastStore = useToastStore();
const { getBackPage } = useHistoryStore();

const { show_header: showHeaderParam, show_footer: showFooterParam } = route.query;

const showFooter = computed(() => {
  const isFooterHidden = showFooterParam === 'false';
  if (isFooterHidden) {
    hideFooter();
  }

  return !window.sessionStorage.getItem('hide_footer');
});

const showHeader = computed(() => {
  const isHeaderHidden = showHeaderParam === 'false';
  if (isHeaderHidden) {
    hideHeader();
  }

  return !window.sessionStorage.getItem('hide_header');
});

onMounted(() => {
  if (!showHeader.value) {
    return;
  }

  const logoElt = document.getElementsByClassName('roomie-header__body').item(0).firstChild;
  logoElt.addEventListener('click', () => {
    navigateTo('/projects');
  });
});

const showCustomerServiceAssistance = computed(() => {
  return userInfo.roleId === allyRoleId || userInfo.roleId === paymentPlanReviewerRoleId;
});

const hideHeader = () => {
  window.sessionStorage.setItem('hide_header', true);
};

const hideFooter = () => {
  window.sessionStorage.setItem('hide_footer', true);
};
</script>

<style lang="scss" scoped>
.layout {
  &__container {
    @apply overflow-auto;
    @apply mt-lh-56;

    overflow: auto;
  }

  &__toast-container {
    @apply absolute top-20 right-0 md:right-10;
    @apply min-w-full md:min-w-[44rem];
  }

  &__slot-container {
    @apply py-lh-24;

    min-height: calc(100vh - 5.6rem - 8.8rem);
    max-height: max-content;
  }

  & .roomie-header {
    @apply h-lh-56;

    box-shadow: 0 4px 8px 2px #485B5733;
  }

  & .roomie-toast {
    @apply fixed;

    z-index: 9999;
  }

  &__customer-service {
    @apply fixed right-[0.5rem] bottom-[10rem] xl:right-[1rem] z-[200];
    @apply grid justify-items-center gap-5;

    &-whatsapp-btn {
      @apply cursor-pointer rounded-full;
      @apply bg-[#147f67] hover:bg-primary-800 text-white;
      @apply w-lh-50 h-lh-50 md:w-[6.5rem] md:h-[6.5rem];
      @apply flex items-center justify-center;
    }

    &-label {
      @apply text-carbon-600 font-medium bg-white;
      @apply hidden xl:block;
    }
  }

  &__icon {
    @apply w-lh-30 h-lh-30 md:w-[4.5rem] md:h-[4.5rem];
  }
}

.roomie-overlay {
  @apply mt-lh-56;
  @apply justify-end items-start;
}
</style>
